<div class="gap-2 w-100 d-flex flex-column justify-content-center">
  <p-message styleClass="w-100" *ngIf="callType === CallType.TextDowngrade"
             [text]='"ENGAGEMENTCONTROL_BUTTON_TEXT_TOOLTIP_NOAV" | Translate : "AV not available for this call"'
             severity="warn"></p-message>


  <p-fieldset legend='{{ "ENGAGEMENTCONTROL_LABEL_CUSTOMER_CONTROLS"  | Translate : "Customer" }}'
              *ngIf="callType !== CallType.TextDowngrade" [toggleable]="true">
    <div class="d-flex flex-column align-items-start justify-content-start gap-3">
      <div class="d-flex flex-row justify-content-center p-buttonset w-100"
           pTooltip="{{'ENGAGEMENTCONTROL_LABEL_MODE' | Translate : 'Mode'}}" tooltipPosition="top">
        <p-button id="text-mode" size="small"
                  [label]="'ENGAGEMENTCONTROL_BUTTON_TEXTMODE' | Translate : 'Text Mode'" (onClick)="switchToText()"
                  [severity]="mode === CommunicationMode.OFF ? 'primary' : 'secondary'">
        </p-button>
        <p-button id="audio-mode" *ngIf="audioAvailable" size="small"
                  [label]="'ENGAGEMENTCONTROL_BUTTON_AUDIO' | Translate : 'Audio Only'" (onClick)="switchToAudio()"
                  [severity]="mode === CommunicationMode.TWOWAYAUDIO_NOVIDEO ? 'primary' : 'secondary'">
        </p-button>
        <p-button id="video-mode"
                  [label]="'ENGAGEMENTCONTROL_BUTTON_VIDEOMODE' | Translate : 'Video Mode'" (onClick)="switchToVideo()"
                  size="small"
                  [severity]="mode === CommunicationMode.TWOWAYAUDIO_VIDEO ? 'primary' : 'secondary'">
        </p-button>
      </div>
      <div class="d-flex flex-row w-100 justify-content-around">
        <div class="d-flex flex-row gap-2">
          <p-button id="text-control" size="small" [severity]="textVisible ? 'primary' : 'secondary'"
                    (onClick)="onTextVisibleChange()" [disabled]="isInBackground || callType === CallType.TextDowngrade"
                    [pTooltip]="'ENGAGEMENTCONTROL_BUTTON_TEXTVISIBILITY' | Translate : 'Toggle Text Visibility'"
                    tooltipPosition="top">
            <fa-icon [fixedWidth]="true" size="lg" [icon]="textVisible ? faComment : faCommentSlash"></fa-icon>
          </p-button>

          <p-button id="speaker-control" size="small" [severity]="mode !== CommunicationMode.OFF && speakerVolume >  0 ? 'primary' : 'secondary'"
                    (onClick)="toggleSpeakerMute()" [disabled]="mode === CommunicationMode.OFF || isInBackground || callType === CallType.TextDowngrade"
                    [pTooltip]="'ENGAGEMENTCONTROL_BUTTON_CUSTOMER_SPEAKER' | Translate : 'Toggle Customer Speaker'"
                    tooltipPosition="top">
            <fa-icon [fixedWidth]="true" size="lg" [icon]="mode !== CommunicationMode.OFF && speakerVolume > 0 ? faVolumeUp : faVolumeMute"></fa-icon>
          </p-button>

          <p-button id="mic-control" size="small"
                    [disabled]="mode === CommunicationMode.OFF || isInBackground || callType === CallType.TextDowngrade"
                    [severity]="customerMicOn ? 'primary' : 'secondary'"
                    (onClick)="toggleCustomerMic()"
                    [pTooltip]="'ENGAGEMENTCONTROL_BUTTON_CUSTOMER_MIC' | Translate : 'Toggle Customer Microphone'"
                    tooltipPosition="top">
            <fa-icon [fixedWidth]="true"  size="lg" [icon]="customerMicOn ? faMicrophone : faMicrophoneSlash"></fa-icon>
          </p-button>

          <p-button *ngIf="!videoUnavailable" size="small" [severity]="customerVideoOn ? 'primary' : 'secondary'"
                    (onClick)="toggleCustomerVideo()"
                    [disabled]="mode === CommunicationMode.OFF || isInBackground || callType === CallType.TextDowngrade"
                    [pTooltip]="'ENGAGEMENTCONTROL_BUTTON_CUSTOMER_VIDEO' | Translate : 'Toggle Customer Video'"
                    tooltipPosition="top">
            <fa-icon [fixedWidth]="true" class="fw-fa" size="lg" [icon]="customerVideoOn ? faVideo : faVideoSlash"></fa-icon>
          </p-button>

          <p-button *ngIf="!isMobileSdk" size="small"
                    [disabled]="mode === CommunicationMode.OFF || isMobile || isInBackground || callType === CallType.TextDowngrade"
                    [severity]="customerCogOn ? 'primary' : 'secondary'"
                    (onClick)="toggleCustomerCog()"
                    [pTooltip]="'ENGAGEMENTCONTROL_BUTTON_CUSTOMER_DEVICE_SETTINGS' | Translate : 'Toggle Device Settings'"
                    tooltipPosition="top">
            <fa-icon [fixedWidth]="true" class="fw-fa" size="lg" [icon]="faCog"></fa-icon>
          </p-button>

        </div>
      </div>
      <div class="d-flex flex-column gap-4 w-100">
        <app-panel-size-slider
          class="px-2"
          *ngIf="showAppSize"
          [currentPanelPosition]="currentPanelPosition"
          (changePanelSize)="changePanelSize.emit($event)">
        </app-panel-size-slider>

        <ng-content select="[slot=panel-position-controls]"></ng-content>
      </div>
    </div>
  </p-fieldset>


</div>
