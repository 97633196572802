<div class="d-flex flex-column overflow-hidden flex-grow-1 sidebar-panel-inner-border-radius">

  <app-engagement-toolbar *ngIf="(engagement?.currentState | async)?.type !== EngagementState.Post" [engagement]="engagement"></app-engagement-toolbar>

  <app-engagement-video [callPaused]="(engagement?.callPaused | async)"
                        [currentFeedType]="(engagement?.currentFeedType$ | async)"
                        [engagementId]="engagement?.engagementId.toString()"
                        [mode]="(engagement?.communicationMode | async)"
                        [panelPositionAndSize]="(engagement?.panelPositionAndSize | async)"
                        [videoUnavailable]="videoUnavailable"
                        class="d-flex flex-column"
  ></app-engagement-video>

  <div class="p-1 overflow-y-auto overflow-x-hidden flex-grow-1">
    <p-tabView #staticTabs styleClass="justified">

      <p-tabPanel *ngIf="true" [disabled]="(engagement?.currentState | async)?.type !== EngagementState.Engaged"
                  header='{{ "CONTROLBAR_HEADER_CONTROLS"  | Translate : "Controls" }}'>


        <div class="d-flex flex-column gap-2 py-1">
          <app-avselection (selectCamera)="onSelectCamera($event)" (selectMicrophone)="onSelectMic($event)"
                           (selectSpeaker)="onSelectSpeaker($event)"
                           [selectedCamera]="(camera$ | async)"
                           [selectedMicrophone]="(mic$ | async)" [selectedSpeakerOutput]="(speaker$ | async)"
                           [showMicSelection]="false"
                           [showSpeakerSelection]="false" [videoUnavailable]="videoUnavailable"
                           class="w-100" #devices>
            <app-camera-controls class="d-flex flex-column w-100"></app-camera-controls>
          </app-avselection>


          <app-engagement-controls
            (changePanelSize)="onChangePanelSize($event)"
            (switchTextVisible)="onSwitchTextVisible($event)"
            (switchToAudioChat)="onSwitchToAudioChat()"
            (switchToTextChat)="onSwitchToTextChat()"
            (switchToVideoChat)="onSwitchToVideoChat()"
            *ngIf="(engagement?.currentState | async)?.type !== EngagementState.Post && engagement?.isPrimaryAgent()"
            [audioAvailable]="audioAvailable"
            [callType]="engagement?.visitor?.callType"
            [currentPanelPosition]="(engagement?.panelPositionAndSize | async)"
            [customerHearingOperator]="(engagement?.hearingOperatorStream | async)"
            [customerViewingOperator]="(engagement?.viewingOperatorStream | async)"
            [mode]="(engagement?.communicationMode | async)"
            [showAppSize]="showAppSize"
            [textVisible]="(engagement?.textVisible | async)"
            [videoUnavailable]="videoUnavailable"
            (changeSpeakerVolume)="onChangeSpeakerVolume($event)"
            [speakerVolume]="(engagement?.speakerVolume | async) * 100"
            [disabled]="(engagement?.isInBackground | async) || engagement?.visitor.callType == CallType.TextDowngrade"
            [isMobileSdk]="engagement?.visitor?.isMobileSdk"
            [isMobile]="isMobile"
            (changeCustomerVideo)="onChangeCustomerVideo($event)"
            [customerVideoOn]="(engagement?.cameraEnabled | async)"
            (changeCustomerCog)="onChangeCustomerCog($event)"
            [customerCogOn]="(engagement?.customerCogOn | async)"
            [customerMicOn]="(engagement?.micEnabled | async)"
            (changeCustomerMic)="onChangeCustomerMic($event)"
            class="h-100 w-100 engagement-controls flex-grow-1"
          >
            <app-engagement-controls-direction-buttons slot="panel-position-controls"
                                                       [pTooltip]="'ENGAGEMENTCONTROL_TITLE_PANELPOSITION' | Translate: 'Chat Panel Position'"
                                                       (panelPositionChanged)="panelPositionChanged($event)"
                                                       *ngIf="engagement?.isPrimaryAgent()"
                                                       [panelPosition]="(engagement?.panelPositionAndSize | async)"
                                                       class="engagement-ctrls-direction-btns w-100"></app-engagement-controls-direction-buttons>

          </app-engagement-controls>

          <app-engagement-assets *ngIf="(engagement?.currentState | async)?.type !== EngagementState.Post"
                                 [disabled]="(engagement.backgrounded | async) || !(engagement?.isPresenter | async)" (selectedAsset)="selectAsset($event)"
                                 class="px-1"></app-engagement-assets>

        </div>


      </p-tabPanel>

      <!-- The ngIf true is required to keep the 'Customer Controls' tab on the right.
           TODO: Look at the solutions in https://github.com/valor-software/ngx-bootstrap/issues/823 for this ngIf rubbish
       -->
      <p-tabPanel
        *ngIf="true"
        [disabled]="(engagement?.currentState | async)?.type !== EngagementState.Engaged || engagement?.visitor.callType == CallType.TextDowngrade || (engagement?.chatOnly | async)"
        class="av-tab customer-av-tab">
        <ng-template pTemplate="header">
          <!-- Pointer events need to be forced on. If the tab is disabled then the tab CSS sets pointer events to 'none' thus no titles. -->
          <span
            [title]='(engagement?.visitor.callType == CallType.TextDowngrade) ? ("ENGAGEMENT_COCKPIT_TOOLTIP_CUSTOMER_NOAV" | Translate: "Customer AV not available for this call.") : ((engagement?.chatOnly | async) ? ("ENGAGEMENT_COCKPIT_TOOLTIP_CUSTOMER_CHATONLY_NOAV" | Translate: "Customer AV not available when in text mode.") : "")'
            style="pointer-events:auto;"
            triggers="pointerenter:pointerout"
          >{{ "ENGAGEMENTCOCKPIT_HEADER_CUSTOMER" | Translate : "Customer" }}</span>
        </ng-template>

        <app-engagement-customer-av-controls
          (changeCameraDirection)="changeCameraDirection.emit($event)"
          (changeCustomerCog)="onChangeCustomerCog($event)"
          (changeCustomerMic)="onChangeCustomerMic($event)"
          (changeCustomerVideo)="onChangeCustomerVideo($event)"
          (changeSpeakerVolume)="onChangeSpeakerVolume($event)"
          [speakerVolume]="(engagement?.speakerVolume | async) * 100"
          *ngIf="(engagement?.currentState | async)?.type === EngagementState.Engaged"
          [cameraAccessGranted]="(engagement?.cameraAccessGranted | async)"
          [cameraAlreadyInUse]="(engagement?.cameraAlreadyInUse | async)"
          [cameraDirection]="engagement?.cameraDirection"
          [customerCogOn]="(engagement?.customerCogOn | async)"
          [customerMicOn]="(engagement?.micEnabled | async)"
          [customerVideoOn]="(engagement?.cameraEnabled | async)"
          [disabled]="(engagement?.isInBackground | async) || engagement?.visitor.callType == CallType.TextDowngrade"
          [isMobileSdk]="engagement?.visitor?.isMobileSdk"
          [isMobile]="isMobile"
          [micAccessGranted]="(engagement?.micAccessGranted | async)"
          [micActivityLevel]="(engagement?.micActivityLevel | async)"
          [micAlreadyInUse]="(engagement?.micAlreadyInUse | async)"
          [videoUnavailable]="videoUnavailable"
        ></app-engagement-customer-av-controls>
      </p-tabPanel>
    </p-tabView>
  </div>

</div>
