import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BrowserService} from '../../services/browser-service/browser.service';
import {BrowserServiceStatus} from '../../services/browser-service/IBrowserService';
import {
    faCircleArrowDown,
    faCircleArrowUp,
    faExpand,
    faMobileScreenButton,
    faPaperPlane,
    faRotate
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-engagement-browser-bar',
  templateUrl: './engagement-browser-bar.component.html',
  styleUrls: ['./engagement-browser-bar.component.scss']
})
export class EngagementBrowserBarComponent implements OnDestroy {

  @Output() switchSharing = new EventEmitter<boolean>();
  @Output() switchSecureSharing = new EventEmitter<boolean>();
  @Output() switchCoBrowse = new EventEmitter<boolean>();
  @Output() pageChanged = new EventEmitter<string>();
  @Output() switchAppView = new EventEmitter<boolean>();

  @Input() isPresenter: boolean;
  @Input() useSharing: boolean;
  @Input() useSecureSharing: boolean;
  @Input() useCoBrowse: boolean;
  @Input() canShare: boolean = false;
  @Input() canSecureShare: boolean = false;
  @Input() canCoBrowse: boolean = false;
  @Input() canAppView: boolean;
  @Input() useAppView: boolean = false;

  public browserLocationForm: UntypedFormGroup;
  public loading: boolean;

  private failedToLoad: boolean;
  private currentPage   :string = "";

  @Input() public set currentUrl(url:string){
    if (!this.failedToLoad && (url === this.currentPage || url === "")) {
      return;
    }

    this.currentPage = url;
    this.browserLocationForm.setValue({ url }, { emitEvent: false });
    if(this.isPresenter){
      this.pageChanged.emit(this.currentPage);
    }
  }

  public get currentUrl():string{
    return this.currentPage;
  }


  constructor(
    private fb: UntypedFormBuilder,
    private browserService: BrowserService
  ) {

    this.browserService.onStatus.subscribe(status => {
      this.loading = status === BrowserServiceStatus.LOADING;
      this.failedToLoad = status === BrowserServiceStatus.FAILED;
    });
    this.createForm();
  }

  private createForm(): void {
    this.browserLocationForm = this.fb.group({
      url: ['', [Validators.required]]
    });
  }

  submit() {

    if (this.browserLocationForm.valid) {

      let url :string = this.browserLocationForm.value['url'];

      if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
        url = `https://${url}`;
      }

      this.currentUrl = url;
    }
  }

  onSecureShareClicked() {
    this.switchSecureSharing.emit(!this.useSecureSharing);
  }

  onShareClicked() {
    this.switchSharing.emit(!this.useSharing);
  }

  onCoBrowseClicked() {
    this.switchCoBrowse.emit(!this.useCoBrowse);
  }

  toggleAppView() {
    this.switchAppView.emit(!this.useAppView);
  }

  ngOnDestroy(){
    this.currentPage = "";
    this.browserService.setStatus(BrowserServiceStatus.NONE);
  }

  protected readonly faExpand = faExpand;
  protected readonly faCircleArrowUp = faCircleArrowUp;
  protected readonly faCircleArrowDown = faCircleArrowDown;
  protected readonly faRotate = faRotate;
  protected readonly faPaperPlane = faPaperPlane;
  protected readonly faMobileScreenButton = faMobileScreenButton;
}
