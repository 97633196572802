<div class="h-100 w-100">
  <div class="d-flex flex-column call-list w-100 h-100">
    <p-tabMenu styleClass="justified" *ngIf="hasAsync" [model]="menuItems" [(activeItem)]="activeItem"></p-tabMenu>
<!--   <p-multiSelect styleClass="px-1" *ngIf="currentAgent.isSupervisor" [(ngModel)]="selectedGroupList" [options]="Array.from(groups)"></p-multiSelect>-->

    <section class="overflow-x-hidden h-100 pt-2">
      <p-panel [showHeader]="groupName !== 'N/A'" [header]="groupName" [toggleable]="true" *ngFor="let groupName of selectedGroupList" >

        <table class="table table-sm table-borderless text-center user-select-none table-fixed w-100" [class.d-none]="groupIsCollapse[groupName]">
          <tbody>
            <tr *ngFor="let engagement of (engagements | async | engagementFilter : groupName)"
                id="ongoing-engagement" role="button"  class="row-height"
                app-veechat-call-list-engagement-row
                [currentPage]="(engagement.currentPage | async)"
                [currentState]="(engagement.currentState | async)"
                [chatName]="(engagement.chatName | async)"
                [unreadMessages]="(engagement.unreadMessages | async)"
                [lastContactTime]="(engagement.lastContactTime | async)"
                [class.selected]="(currentSelection | async).type === VeechatSelection.engagement && (currentSelection | async).id == engagement.engagementId"
                (renameChat)="onRenameChat(engagement, $event)"
                (click)="selectEngagement(engagement)">
            </tr>

            <tr *ngFor="let conversation of (this.visitorService.conversations | async)"
                id="ongoing-conversation"
                class="ongoing-row row-height" role="button"
                app-veechat-call-list-conversation-row
                [currentPage]=""
                [ended]="conversation.isEnded"
                [chatName]="conversation.chatName"
                (renameChat)="conversation.renameChat($event)"
                [unreadMessages]="conversation.unreadMessages"
                [lastMessageFailed]="conversation.lastAgentMessageFailed"
                [lastContactTime]="conversation.lastCustomerMessageTimestamp"
                [channel]="conversation.channel"
                [class.selected]="(currentSelection | async).type === VeechatSelection.conversation && (currentSelection | async).id == conversation.id.Id"
                (click)="selectConversation(conversation)">
            </tr>

            <tr *ngFor="let transfer of (transfers | async | invitesFilter : groupName)"
                app-veechat-call-list-row
                id="incoming-transfer" role="button" class="row-height"
                [visitor]="transfer.visitor"
                (acceptCall)="showTransferAccept(transfer)"
                [class.selected]="(currentSelection | async).type === VeechatSelection.transfer && (currentSelection | async).transferRequest.visitor.userGuid === transfer.visitor.userGuid"
                (click)="selectTransferIn(transfer)">
            </tr>

            <tr *ngFor="let invite of (invites | async | invitesFilter : groupName)"
                app-veechat-call-list-row
                id="incoming-join" role="button" class="row-height"
                [visitor]="invite.visitor"
                (acceptCall)="showJoinAccept(invite)"
                [class.selected]="(currentSelection | async).type === VeechatSelection.invite && (currentSelection | async).joinRequest.visitor.userGuid === invite.visitor.userGuid"
                (click)="selectJoinIn(invite)">
            </tr>

            <tr *ngFor="let visitor of (visitors | visitorFilter : groupName)"
                app-veechat-call-list-row
                id="incoming-call" role="button" class="row-height"
                [visitor]="visitor"
                (acceptCall)="accept(visitor)"
                [class.warning]="!(visitor.isInactive || visitor.inColdTransferState) && visitor.getAssistTime() > 15"
                [class.danger]="!(visitor.isInactive || visitor.inColdTransferState) && visitor.getAssistTime() > 30"
                [class.selected]="(currentSelection | async).type === VeechatSelection.visitor && (currentSelection | async).visitor.userGuid == visitor.userGuid"
                [class.inactive]="(visitor.isInactive || visitor.inColdTransferState)"
                (click)="selectVisitor(visitor)"
                [title]="visitor.isInactive ? ('CALLLIST_ROW_USER_INACTIVE' | Translate : 'User is no longer active') : ''">
            </tr>
          </tbody>
        </table>
      </p-panel>
    </section>
  </div>
</div>
