import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AlertService, AlertType} from '../../../../services/alert-service/alert.service';
import {LoggingService} from '../../../../services/logging.service';
import {OnlineService} from '../../../../services/online.service';
import {UserAgentDetectorService} from '../../../../services/user-agent-detector.service';
import {PanelSize} from '../../../../services/engagement';
import {EngagementVideoComponent} from '../../engagement/engagement-video/engagement-video.component';
import * as MultiPeerWebRTC from 'multi-peer-webrtc';
import {environment} from '../../../../../environments/environment';
import { Subscription, distinctUntilChanged, skip } from 'rxjs';
import { VirtualBackground } from '../../../../classes/virtualBackground';

@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss']
})
export class VideoPreviewComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  private _device: MediaDeviceInfo;
  private _processingPipe: Promise<MultiPeerWebRTC.ProcessingPipe | null> = Promise.resolve(null);
  private _backgroundConfig: MultiPeerWebRTC.BackgroundConfig = null;
  private _postProcessingConfig: MultiPeerWebRTC.PostProcessingConfig = null;

  @Input() showHeadPositionOverlay: boolean;

  private _panelSize: PanelSize = PanelSize.Normal;

  @Input() set panelSize(v: PanelSize) {
    if (this._panelSize != v) {
      this._panelSize = v;
      this.update();
    }
  }

  get panelSize(): PanelSize {
    return this._panelSize;
  }

  @Input() set device(newDevice: MediaDeviceInfo) {
    this.loggingService.info('Video Preview input device changed');

    this._device = newDevice;
    this.update();
  }

  private update() {
    this._processingPipe = this._processingPipe.then((pipe) => {
      this.disposePipe(pipe);
      if (this._device) {
        return this.createPreview();
      } else {
        return Promise.resolve(null);
      }
    });
  }

  public source: MediaStream;
  public isVideoFullScreen = false;

  constructor(
    private readonly alertService: AlertService,
    private readonly loggingService: LoggingService,
    private readonly userAgentDetectorService: UserAgentDetectorService,
    public readonly onlineService: OnlineService
  ) {
  }

  ngOnInit() {
    this.onlineService.virtualBackground.next(null);
    this.subs.push(this.onlineService.virtualBackground.pipe(distinctUntilChanged(), skip(1)).subscribe(v => {
      this.setupVirtualBackground(v);
    }));
  }


  ngOnDestroy(): void {
    this.loggingService.info('Destroying Video Preview');
    this._processingPipe.then(pipe => this.disposePipe(pipe));
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

  private setupVirtualBackground(virtualBackground:VirtualBackground) {
    const currentUrl = new URL(window.location.href);
    const resourcesUrl = `${currentUrl.protocol}//${currentUrl.hostname}${currentUrl.port ? ':' + currentUrl.port : ''}/assets/mpw-vb-resources`;

    this._backgroundConfig = virtualBackground ? new MultiPeerWebRTC.BackgroundConfig({
      resourcesPath: resourcesUrl,
      type: VirtualBackground.convert(virtualBackground.type),
      url: `${environment.assetProxy}${virtualBackground.url}`
    }) : null;

    this._postProcessingConfig = virtualBackground ? new MultiPeerWebRTC.PostProcessingConfig({
      sigmaSpace : virtualBackground.jointBilateralFilterSigmaSpace,
      sigmaColor : virtualBackground.jointBilateralFilterSigmaColor,
      coverageMin : virtualBackground.backgroundCoverageMin,
      coverageMax : virtualBackground.backgroundCoverageMax,
      lightWrapping : virtualBackground.backgroundLightWrapping,
      blendMode : virtualBackground.backgroundBlendMode
    }) : null;

    this.update();

  }

  private async createPreview(): Promise<MultiPeerWebRTC.ProcessingPipe | null> {
    this.loggingService.info('Video Preview createPreview');

    const constraints = this.getConstraints();

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);

      if (this._backgroundConfig) {
        const pipe = new MultiPeerWebRTC.ProcessingPipe();
        try {
          await pipe.initialise({
            width: constraints.video['width']['ideal'] || constraints.video['width'],
            height: constraints.video['height']['ideal'] || constraints.video['height'],
            backgroundConfig: this._backgroundConfig,
            postProcessingConfig: this._postProcessingConfig
          });
          this.source = pipe.processVideoStream(mediaStream);
          this.loggingService.info('Video Preview createPreview setting source');
          return pipe;
        } catch (err) {
          pipe.dispose();
          this.alertService.addAlert('Unable to create processing pipeline for your camera: ' + this.getCamName(), AlertType.Danger);
          this.loggingService.error('Unable to create MultiPeerWebRTC.ProcessingPipe', err);
          return null;
        }
      } else {
        this.source = mediaStream;
        return null;
      }

    } catch (err) {
      this.alertService.addAlert('Unable to get preview for your camera: ' + this.getCamName(), AlertType.Danger);
      this.loggingService.error('Unable to get camera preview', err);
      return null;
    }
  }

  private disposePipe(pipe: MultiPeerWebRTC.ProcessingPipe | null): void {
    this.loggingService.info('Removing processing pipe');
    pipe?.dispose();

    if (this.source) {
      for (const track of this.source.getTracks()) {
        track.stop();
      }
    }
    this.source = null;
  }

  private getCamName(): string {
    return this._device.label.length > 0 ? this._device.label : this._device.deviceId;
  }

  private getConstraints(): MediaStreamConstraints {

    if (this.userAgentDetectorService.isSafari()) {
      // Use a different set of constraints for Safari
      // as it doesn't give us the "nearest" match
      return {
        video: {
          deviceId: {exact: this._device.deviceId},
          width: {ideal: 320},
          height: {ideal: 240},
        }
      };
    } else {
      let width: number = 352;
      let height: number = 198;

      const size = EngagementVideoComponent.SIZE_LOOKUP.get(this.panelSize);

      if (size && size.length === 2) {
        width = size[0];
        height = size[1];
      }

      return {
        video: {
          deviceId: this._device.deviceId,
          width,
          height
        }
      };
    }
  }

  // toggle fullscreen video by adding/removing styles
  public toggleFullScreenVideo() {
    if (this.isVideoFullScreen) {
      document.getElementById('videoContainer').classList.remove('fullscreenVideo');
      document.getElementById('videoContainer').classList.add('w-100');
    } else {
      document.getElementById('videoContainer').classList.add('fullscreenVideo');
      document.getElementById('videoContainer').classList.remove('w-100');
    }
    this.isVideoFullScreen = !this.isVideoFullScreen;
  }

}
