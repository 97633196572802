import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {PasswordResetComponent} from './pages/password-reset/password-reset.component';
import {PasswordChangeComponent} from './pages/password-change/password-change.component';
import {PasswordChangeFromLinkComponent} from './pages/password-change-from-link/password-change-from-link.component';
import {VeestudioComponent} from './pages/veestudio/veestudio.component';
import {VeeStudioAuthGuard} from './guards/veestudio-authguard.service';
import {EngagementComponent} from './pages/veestudio/engagement/engagement.component';
import {CallListComponent} from './components/call-list/call-list.component';
import {VeechatCallListComponent} from './pages/veechat/veechat-call-list/veechat-call-list.component';
import {VeechatComponent } from './pages/veechat/veechat.component';
import {VeechatAuthGuard} from './guards/veechat-auth.guard';
import { EngagementPostComponent } from './components/engagement-post/engagement-post.component';
import { CrmOpportunityComponent } from './pages/crm-opportunity/crm-opportunity.component';
import { AppointmentSearchComponent } from './pages/appointment-search/appointment-search.component';
import {SupervisorComponent} from './pages/supervisor/supervisor.component';
import {MobilePresenterComponent} from './pages/mobile-presenter/mobile-presenter.component';
import {MobilePresenterGuard} from './guards/mobile-presenter.guard';
import {MobilePresenterCallComponent} from './pages/mobile-presenter/mobile-presenter-call/mobile-presenter-call.component';
import {MobilePresenterWaitingComponent} from './pages/mobile-presenter/mobile-presenter-waiting/mobile-presenter-waiting.component';
import {SupervisorAuthGuard} from './guards/supervisor-auth.guard';
import { OnlineState } from './enums/online-state.enum';
import { EngagementEventTypes } from './services/engagement';
import { LicenceType } from './enums/licence-type.enum';
import { HomeComponent } from './pages/home/home.component';
import { HomeAuthGuard } from './guards/home-auth.guard';
import { VideoWindowComponent } from './pages/veestudio/engagement/engagement-video/engagement-video-electron/video-window/video-window.component';
import { VeeSchedulerAuthGuard } from './guards/veescheduler-auth.guard';
import { VeeschedulerComponent } from './pages/veescheduler/veescheduler.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'password-change', component: PasswordChangeComponent, canActivate: [HomeAuthGuard] },
  { path: 'password-change-from-link', component: PasswordChangeFromLinkComponent },
  { path: 'home', component: HomeComponent, canActivate: [HomeAuthGuard] },
  { path: 'video-window', component: VideoWindowComponent },
  { path: 'veestudio',
    data: {
      engagementEventType: EngagementEventTypes.VeeStudio,
      availableState: OnlineState.Available,
      connectionType: LicenceType.VeeStudio
    },
    component: VeestudioComponent,
    canActivate: [VeeStudioAuthGuard],

    children: [
      { path: '', component: CallListComponent },
      { path: 'crm', component: CrmOpportunityComponent},
      { path: 'appointments', component: AppointmentSearchComponent},
      { path: 'engagement/:engagementId',
        component: EngagementComponent,
        children: [
          { path: 'post', component: EngagementPostComponent },
        ]
      },
    ],
  },
  { path: 'veechat',
    component: VeechatComponent,
    canActivate: [VeechatAuthGuard],
    children: [
      { path: '', component: VeechatCallListComponent },
      { path: 'crm', component: CrmOpportunityComponent},
      { path: 'appointments', component: AppointmentSearchComponent},
    ],
  },
  {
    path: 'supervisor',
    component: SupervisorComponent,
    canActivate: [SupervisorAuthGuard],
    children: [
      { path: 'crm', component: CrmOpportunityComponent},
      { path: 'appointments', component: AppointmentSearchComponent}
    ]
  },
  {
    path: 'presenter',
    component: MobilePresenterComponent,
    canActivate: [MobilePresenterGuard],
    children: [
      { path: '', component: MobilePresenterWaitingComponent },
      { path: 'engagement/:engagementId', component: MobilePresenterCallComponent }
    ]
  },
  {
    path: 'veescheduler',
    component: VeeschedulerComponent,
    canActivate: [VeeSchedulerAuthGuard],
    children: [
      { path: '', component: VeeschedulerComponent },
    ]
  },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
