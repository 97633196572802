<div class="d-flex flex-column gap-2 sidebar-panel-inner-border-radius overflow-hidden">
  <div #camSelector *ngIf="(camera$ | async)?.label !== '' && !videoUnavailable; else noCamera">
    <app-video-preview [device]="(camera$ | async)"
                       [panelSize]="panelSize"
                       [showHeadPositionOverlay]="true"
                       class="video-preview d-flex w-100">
    </app-video-preview>
  </div>

  <ng-template #noCamera>
    <div class="no-cam-previews w-100 h-100">
      <img class="w-100 h-100"
           src="./assets/images/audio_mode.png"/>
    </div>
  </ng-template>


  <div class="d-flex flex-column w-100 h-100  align-items-center">
    <app-avselection (selectCamera)="onSelectCamera($event)" (selectMicrophone)="onSelectMic($event)"
                     (selectSpeaker)="onSelectSpeaker($event)"
                     [selectedCamera]="(camera$ | async)" [selectedMicrophone]="(mic$ | async)"
                     [selectedSpeakerOutput]="(speaker$ | async)" [showMicSelection]="true"
                     [showSpeakerSelection]="true" [videoUnavailable]="videoUnavailable"
                     class="w-100">
      <app-camera-controls class="d-flex flex-column w-100"></app-camera-controls>
    </app-avselection>


  </div>


</div>

