import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EngagementAgent, EngagementState} from '../../../services/engagement';
import {OnlineState} from '../../../enums/online-state.enum';
import {AgentStatus} from '../../../classes/visitor/AgentStatus';
import {BehaviorSubject, Observable, Subject, Subscription, timer} from 'rxjs';
import {LicenceType} from '../../../enums/licence-type.enum';
import {SettingsService} from '../../../services/settings-service/settings.service';
import {VisitorService} from '../../../services/visitor-service/visitor.service';
import {distinctUntilChanged, tap} from "rxjs/operators";
import {faArrowDownAZ, faArrowUpAZ} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-engagement-transfer-agent-select',
  templateUrl: './engagement-transfer-agent-select.component.html',
  styleUrls: ['./engagement-transfer-agent-select.component.scss']
})
export class EngagementTransferAgentSelectComponent implements OnInit {
  private static readonly ANY_DEPARTMENT = '';

  @Input() roomAgents: ReadonlyMap<string, EngagementAgent> = new Map([]);
  @Input() crossSessionTransfer: boolean = true;

  private _showVeestudioAgents: boolean = true;
  public get showVeestudioAgents() {
    return this._showVeestudioAgents;
  }
  @Input() set showVeestudioAgents(showVeestudioAgents: boolean) {
    this._showVeestudioAgents = showVeestudioAgents;
    this.showVeestudioAgentsChange.emit(this._showVeestudioAgents);
  }
  @Output() public showVeestudioAgentsChange = new EventEmitter<boolean>();

  private _selectedAgent: AgentStatus;
  public get selectedAgent() {
    return this._selectedAgent;
  }
  @Input() set selectedAgent(selectedAgent: AgentStatus) {
    this._selectedAgent = selectedAgent;
    this.selectedAgentChange.emit(this._selectedAgent);
  }
  @Output() public selectedAgentChange = new EventEmitter<AgentStatus>();

  public selectedDepartment = '';

  protected readonly EngagementState = EngagementState;

  private subscriptions: Subscription[] = [];

  public totalAgentStatus: BehaviorSubject<Map<string, AgentStatus>>;

  public currentDate: BehaviorSubject<Date> = new BehaviorSubject(new Date());

  private _departments$: Subject<string[]> = new Subject();
  public departments$: Observable<string[]> = this._departments$.pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)), tap(console.warn));

  public orderByField: string = "lastname";

  constructor(
    private visitorService: VisitorService
  ) { }

  ngOnInit() {
    this.selectedAgent = null;
    this.selectedDepartment = EngagementTransferAgentSelectComponent.ANY_DEPARTMENT;

    this.totalAgentStatus = this.visitorService.agentStatus;
    const agentUpdateSub = this.totalAgentStatus.subscribe(agents => this.updateGroupList(agents));
    this.subscriptions.push(agentUpdateSub);

    const timerSub = timer(0, 1000).subscribe(() => {
      this.currentDate.next(new Date());
    });
    this.subscriptions.push(timerSub);

    this.showVeestudioAgents = this.visitorService.agentLicenceType !== LicenceType.VeeChat;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  displayAgents(isVeeStudio: boolean) {
    this.showVeestudioAgents = isVeeStudio;

    if (this.selectedAgent) {
      this.selectedAgent = this.totalAgentStatus.value.get(this.selectedAgent.username);
    }

    if (this.selectedAgent) {
      if (isVeeStudio && this.selectedAgent.status === OnlineState.Available) {
        return;
      } else if (!isVeeStudio && this.selectedAgent.status === OnlineState.MultiChat) {
        return;
      }
    }

    this.selectedAgent = null;
  }

  private toggleSortField() {
    this.orderByField = this.orderByDesc ? this.orderByField.substring(1) : `-${this.orderByField}`;
  }

  public get orderByDesc():boolean {
    return this.orderByField.startsWith("-");
  }

  private updateGroupList(agents: Map<string, AgentStatus>) {
    const departments = new Set<string>();
    for (const info of agents.values()) {
      info.groups.forEach(group => departments.add(group.Name));
    }
    this._departments$.next(Array.from(departments));
  }

  /**
   * On change department
   * @param department Selected department name
   */
  public selectDepartment(department: string) {
    this.selectedDepartment = department;

    const status = this.totalAgentStatus.value.get(this.selectedAgent?.username);
    if (status) {
      // If the agent is not a memeber of the new department selection clear them
      if (department !== EngagementTransferAgentSelectComponent.ANY_DEPARTMENT && !status.groups.some(group => group.Name === department)) {
        this.selectedAgent = null;
      }
    } else {
      this.selectedAgent = null;
    }
  }

  timeSinceLastStatusChange(status: Date, currentDate: Date) {
    const seconds = Math.floor(Math.max(0, currentDate.getTime() - status.getTime()) / 1000);

    if (seconds > 3599) {
      return '1 hr+';
    } else if (seconds < 60) {
      return `00:${this.formatLeadingZero(seconds)}`;
    } else {
      return `${this.formatLeadingZero(Math.floor(seconds / 60))}:${this.formatLeadingZero(Math.floor(seconds % 60))}`;
    }
  }

  private formatLeadingZero(num: number): string {
    num = Math.floor(num);
    return (num > 9 ? "" : "0") + num;
  }

  protected readonly faArrowDownAZ = faArrowDownAZ;
  protected readonly faArrowUpAZ = faArrowUpAZ;
}
