import {Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, Type, ViewChild} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {AlertService} from '../../../../services/alert-service/alert.service';
import {LoggingService} from '../../../../services/logging.service';
import {CustomerSizes, PanelSize, WebrtcMessageType, CommunicationMode, Engagement, EngagementState} from '../../../../services/engagement';
import {OnlineService} from '../../../../services/online.service';
import {AuthService} from '../../../../services/auth-service/auth.service';
import {EngagementService} from '../../../../services/engagement.service';
import {WebrtRTCMessagingService} from '../../../../services/webrtc-messaging-service/webrtcmessaging.service';
import { FeedType } from '../../../../enums/multipeer/feed-type.enum';
import { TranslatePipe } from '../../../../filters/Translate.pipe'

import { VersionService } from '../../../../services/version-service/version.service';
import { Features, FeatureService } from '../../../../services/feature-service/feature.service';

import { faCompress } from '@fortawesome/free-solid-svg-icons';
import { EngagementVideoAnchorDirective } from '../../../../directives/engagement-video-anchor.directive';
import { EngagementVideoBrowserComponent } from './engagement-video-browser/engagement-video-browser.component';
import { IVideoComponent } from './IVideoComponent';
import { ComponentRef } from '@angular/core';
import { EngagementVideoElectronComponent } from './engagement-video-electron/engagement-video-electron.component';
import { VirtualBackground } from '../../../../classes/virtualBackground';
import {first} from 'rxjs/operators';

export class VideoComponent {
  constructor(public component: Type<any>) {}
}

type Width = number;
type Height = number;
type Size = [Width, Height];

@Component({
  selector: 'app-engagement-video',
  templateUrl: './engagement-video.component.html',
  styleUrls: ['./engagement-video.component.scss']
})
export class EngagementVideoComponent implements OnInit, OnDestroy {

  public static readonly SIZE_LOOKUP = new Map<PanelSize, Size>(
    [[PanelSize.Small, [192, 108]],
      [PanelSize.Normal, [352, 198]],
      [PanelSize.Big, [640, 360]],
      [PanelSize.HD, [1280, 720]],
    ]);

  faCompress = faCompress;
  CommunicationMode = CommunicationMode; // export for the UI

  private subscriptions: Subscription[] = [];

  @ViewChild(EngagementVideoAnchorDirective, {static: true}) engagementVideoAnchor!: EngagementVideoAnchorDirective;
  private componentRef:ComponentRef<IVideoComponent>;
  public videoInstance:IVideoComponent;


  private _callInitialised = false;
  public set callInitialised(value:boolean) {
    this._callInitialised = value;
  }
  public get callInitialised(): boolean {
    return this._callInitialised;
  }

  private _engagement: Engagement;
  public get engagement() : Engagement {
    return this._engagement;
  }

  private username: string;


  private _undocked:boolean = false;
  public set undocked(value:boolean) {
    this._undocked = value;
    if (this.videoInstance) {
      this.videoInstance.undocked = value;
    }
  }
  public get undocked() : boolean {
    return this._undocked;
  }

  public isBigCustomerVideoAvailable = false;

  @Input() videoUnavailable: boolean;

  public commMode: CommunicationMode = CommunicationMode.OFF;
  @Input() set mode (mode: CommunicationMode) {
    //console.log(`################ NEW MODE->>>${mode}<<<-`);
    this.commMode = mode;

    if (this.videoInstance) {
      if (this.commMode === CommunicationMode.TWOWAYAUDIO_NOVIDEO) {
        if (!this._callPaused) {
          this.videoInstance.audioChat();
          this._connectTracks = true;
        }
      } else if (this.commMode === CommunicationMode.TWOWAYAUDIO_VIDEO) {
        if (!this._callPaused) {
          this.videoInstance.videoChat();
          this._connectTracks = true;
        }
      } else {
          this.videoInstance.textChat();
          this._connectTracks = false;
      }
    }
  }

  public feedType: FeedType = FeedType.Agent;
  @Input() set currentFeedType (currentFeedType: FeedType) {
    this.feedType = currentFeedType;
    if (this.videoInstance) {
      if (this.feedType === FeedType.Agent) {
        this.mode = this.commMode;
      } else if (this.feedType === FeedType.Fixed || this.feedType === FeedType.Mobile) {
        this.videoInstance.textChat();
        this._connectTracks = false;
      }
    }
  }

  private _videoSize: PanelSize = PanelSize.Normal;
  @Input() set panelPositionAndSize(panelPositionAndSize: CustomerSizes) {
    if (panelPositionAndSize.videoSize >= 0) {
      if (this._videoSize !== panelPositionAndSize.videoSize) {
        this._videoSize = panelPositionAndSize.videoSize;
        this.changeSize(this._videoSize);
      }
    }
  }


  private _callPaused = true;
  @Input() set callPaused(callPaused: boolean) {
    this._callPaused = callPaused;
    if (callPaused) {
      this.pauseCall();
    } else {
      this.resumeCall();
    }
  }

  private _engagementId:string;
  @Input() set engagementId(engagementId: string) {
    if (engagementId == null || engagementId === '') {
      // tear down
      this.tearDown();
    } else {
      this._engagementId = engagementId;
    }
  }
  get engagementId() : string {
    return this._engagementId;
  }

  private _connectTracks = false;

  public cam: BehaviorSubject<MediaDeviceInfo>;
  private get cameraId(): string {
    if (this.cam && this.cam.value) {
      return this.cam.value.deviceId;
    } else {
      return '';
    }
  }

  public mic: BehaviorSubject<MediaDeviceInfo>;
  // private get micId(): string {
  //   if (this.mic && this.mic.value) {
  //     return this.mic.value.deviceId;
  //   } else {
  //     return '';
  //   }
  // }

  public speaker: BehaviorSubject<MediaDeviceInfo>;
  // private get speakerId(): string {
  //   if (this.speaker && this.speaker.value) {
  //     return this.speaker.value.deviceId;
  //   } else {
  //     return '';
  //   }
  // }

  constructor(
    private alertService: AlertService,
    private logger: LoggingService,
    private authService: AuthService,
    private onlineService: OnlineService,
    private engagementService: EngagementService,
    private webrtcMessagingService: WebrtRTCMessagingService,
    private translate: TranslatePipe,
    private versionService:VersionService,
    private featureService: FeatureService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.cam = this.onlineService.selectedCamera;
    this.mic = this.onlineService.selectedMic;
    this.speaker = this.onlineService.selectedSpeaker;
  }

  ngOnInit() {
    this.logger.debug("ngOnInit");
    this.isBigCustomerVideoAvailable = (!this.versionService.isElectron || this.versionService.electronVersion >= '11.4.1') && this.featureService.has(Features.BIG_CUSTOMER_VIDEO);
    if (!this.videoUnavailable) {
      this.loadVideoComponent();
    }
  }

  ngOnDestroy() {
    this.logger.debug("ngOnDestroy");
    this.tearDown();
  }

  loadVideoComponent() {

    this.logger.debug("loadVideoComponent");

    // get the correct component depending upon whether we are electron or browser based
    const videoComponent = (!this.versionService.isElectron || this.versionService.electronVersion < '11.4.1') ? new VideoComponent(EngagementVideoBrowserComponent) : new VideoComponent(EngagementVideoElectronComponent);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(videoComponent.component);

    const viewContainerRef = this.engagementVideoAnchor.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent<IVideoComponent>(componentFactory);
    if (this.componentRef) {
      this.videoInstance = this.componentRef.instance;

      // inputs
      this.videoInstance.dragResizeEnabled = this.dragResizeEnabled;
      this.videoInstance.undocked = this.undocked;

      // outputs
      this.subscriptions.push(
        this.videoInstance.oncreated.subscribe(() => {
          this.init(this._engagementId);
        })
      );
      this.subscriptions.push(
        this.videoInstance.oninitialised.subscribe(() => {
          this.onInitialised();
        })
      );
      this.subscriptions.push(
        this.videoInstance.onerror.subscribe(message => {
          this.onError(message);
        })
      );
      this.subscriptions.push(
        this.videoInstance.ondockchange.subscribe(undocked => {
          this.onDockChange(undocked);
        })
      );
      this.subscriptions.push(
        this.videoInstance.onsignallingmessage.subscribe(message => {
          this.sendMessage(message);
        })
      );

    }
  }

  init(engagementId: string) {

    this.username = this.authService.currentAgent.value.username;
    this._engagement = this.engagementService.getEngagement(engagementId);

    this.subscriptions.push(
      this.engagement.currentState.subscribe(state => {
        if (state.type === EngagementState.Post || state.type === EngagementState.Ended) {
          this.onDockChange(false);
        }
        if (this.videoInstance) {
          this.videoInstance.dragResizeEnabled = this.dragResizeEnabled;
        }
      })
    );

    this.subscriptions.push(
      this.webrtcMessagingService.clientMessages$.subscribe(message => {
        switch (message.type) {
          case WebrtcMessageType.AddStream:
            //this.logger.debug(`Adding stream for: ${message.streamName} ${message.peerType}`);
            this.addStream(message.streamName, message.peerType);
            break;
          case WebrtcMessageType.RemoveStream:
            //this.logger.debug(`Removing stream for: ${message.streamName}`);
            this.removeStream(message.streamName);
            break;
          case WebrtcMessageType.SignallingMessage:
            this.processMessage(message.message);
            break;
        }
      })
    );

    this.logger.debug(`init this.videoInstance:${this.videoInstance}`);

    if (!this.videoInstance) return;

    const virtualBackground:VirtualBackground = this._engagement.visitor.virtualBackground.value;
    this.onlineService.virtualBackgroundOn.next(virtualBackground ? virtualBackground.onByDefault : false);
    const virtualBackgroundOn:boolean = this.onlineService.virtualBackgroundOn.value;

    this.engagement.authenticatedIceUrl$.pipe(first()).subscribe((webrtcIceServer) => {
      this.logger.debug(`Setting up with video using ice server ${webrtcIceServer}`);
      this.videoInstance.init(engagementId, webrtcIceServer, this._connectTracks, this.username, this.cameraId, virtualBackground, virtualBackgroundOn);
    });
  }

  private onInitialised() {
    if (!this.videoUnavailable) {
      this.callInitialised = true;

      this.subscriptions.push(
        this.cam.subscribe(newcam => {
          this.changeCamera(newcam);
        })
      );
      this.subscriptions.push(
        this.mic.subscribe(newmic => {
          this.changeMic(newmic);
        })
      );
      this.subscriptions.push(
        this.speaker.subscribe(newspeaker => {
          this.changeSpeaker(newspeaker);
        })
      );
      this.subscriptions.push(
        this.engagement.onPrimaryCustomerChanged$.subscribe(customer => {
          this.videoInstance.setPrimaryVisitor(customer);
        })
      );
    }
  }

  private onError(message:string) {
    this.logger.error(`Unable to startAudioVideo. ${message}`);
    this.alertService.addAlert(this.translate.transform("ENGAGEMENTVIDEO_ALERT_CAMERA", 'Unable to start camera'), AlertService.DANGER);
  }

  private tearDown() {
    // This method is a bit of a bodge to emulate a delayed init and tear down
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];

    this.callInitialised = false;
    this.undocked = false;

    if (this.videoInstance) {
      this.videoInstance.tearDown();
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  private processMessage(message: any) {
    if (this.videoInstance) {
      this.videoInstance.processMessage(message);
    }
  }

  private addStream(streamName: string, peerType: any) {
    if (this.videoInstance) {
      this.videoInstance.addStream(streamName, peerType);
    }
  }

  private removeStream(streamName: string) {
    if (this.videoInstance) {
      this.videoInstance.removeStream(streamName);
    }
  }

  public sendMessage(message: any) {
    this.webrtcMessagingService.sendMessageToServer(JSON.stringify(message));
  }

  private changeCamera(camera: MediaDeviceInfo) {
    if (camera && this.videoInstance && this.callInitialised) {
      const size = EngagementVideoComponent.SIZE_LOOKUP.get(this._videoSize);
      this.videoInstance.changeCamera(camera.deviceId, size[0], size[1]);
    }
  }

  private changeMic(mic: MediaDeviceInfo) {
    if (mic && this.videoInstance && this.callInitialised) {
      this.videoInstance.changeMic(mic.deviceId);
    }
  }

  private changeSpeaker(speaker: MediaDeviceInfo) {
    if (speaker && this.videoInstance && this.callInitialised) {
      this.videoInstance.changeSpeaker(speaker.deviceId);
    }
  }

  private changeSize(size: PanelSize) {
    if (this.cameraId && this.videoInstance && this.callInitialised) {
      const videoSize = EngagementVideoComponent.SIZE_LOOKUP.get(size);
      this.videoInstance.changeCamera(this.cameraId, videoSize[0], videoSize[1]);
    }
  }

  private pauseCall() {
    if (this.videoInstance) {
      this.logger.debug('Pausing call');
      this.videoInstance.pauseCall();
    }
  }

  private resumeCall() {
    if (this.feedType === FeedType.Agent) {
      if (this.videoInstance) {
        this.logger.debug('Resuming call');
        this.videoInstance.resumeCall();
        this.mode = this.commMode;
      }
    }
  }


  public onDockChange(event:boolean) : void {
    if (!this.isBigCustomerVideoAvailable) return;
    this.undocked = event.valueOf();
    if (this.engagement) {
      this.engagement.agentVideoUnDocked(this.undocked);
    }
  }

  public get engaged() : boolean {
    return (this.engagement
      && this.engagement.currentState.getValue().type !== EngagementState.Post
      && this.engagement.currentState.getValue().type !== EngagementState.Ended
    );
  }

  public get dragResizeEnabled() : boolean {
    return this.isBigCustomerVideoAvailable && this.engaged;
  }

}
