import {UAParser} from 'ua-parser-js';

export interface BrowserInformation {
  browser: string;
  browserIcon: string;
  deviceType: string;
  deviceTypeIcon: string;
  os: string;
  version: string;
  webrtcBlocked: boolean;
}

type Browser = string;
type IconPath = string;

interface BlockedBrowser {
  browser: string;
  os: string;
}

export class Browsers {
  private static readonly parser = new UAParser();
  private static readonly ICON_LOOKUP = new Map<Browser, IconPath>(
    [ ['android sdk', 'android_sdk.png'],
      ['ios sdk', 'apple_sdk.png'],
      ['tizen', 'tizen_sdk.png'],
      ['edge', 'ie.png'],
      ['silk', 'amazon-icon.png'],
      ['android', 'android.png'],
      ['iphone', 'apple2.png'],
      ['ipad', 'apple2.png'],
      ['playbook', 'blackberry.png'],
      ['opera', 'opera.png'],
      ['chrome', 'chrome.png'],
      ['firefox', 'firefox.png'],
      ['ie11', 'ie.png'],
      ['internet explorer', 'ie.png'],
      ['konqueror', 'konqueror.png'],
      ['netscape', 'netscape.png'],
      ['safari', 'safari.png'],
      ['mobile safari', 'safari.png'],
      ['ios facebook', 'facebook.png'],
      ['facebook', 'facebook.png'],
      ['ios chrome', 'chrome-ios.png'],
      ['ios firefox', 'firefox-ios.png'],
      ['ios instagram', 'instagram.png'],
      ['instagram', 'instagram.png'],
      ['samsung browser', 'samsung_internet.png'],
    ]);

  public static readonly WEBRTC_BLOCK_LIST_RESOURCE: string = "BROWSERS_BLOCK_WEBRTC";
  private static WebrtcBlockList: Array<BlockedBrowser> = [];

  public static WebrtcBlocked(browser: string, os: string): boolean {
    const browserEquality: (testInfo:BlockedBrowser) => boolean = testInfo => (testInfo.browser == browser.toLowerCase())
                                                                              && (testInfo.os == os.toLowerCase());
    return Browsers.WebrtcBlockList.some(browserEquality);
  }

  public static PopulateWebrtcBlockList(blockedBrowsers: string) {
    Browsers.WebrtcBlockList = [];

    if (blockedBrowsers) {
      const blockList = blockedBrowsers.split(',');
      for (let i = 0; i < blockList.length; i += 2) {
        const browser = (blockList[i] || "").toLowerCase();
        const os = (blockList[i+1] || "").toLowerCase();
        Browsers.WebrtcBlockList.push({ browser, os });
      }
    }
  }

  private static CreateUnknownBrowser(): BrowserInformation {
    const browser: BrowserInformation = {
      browser: 'Unknown',
      browserIcon: '32x32_redcross.png',
      deviceType: 'desktop',
      deviceTypeIcon: 'desktop.svg',
      os: 'Unknown',
      version: '',
      webrtcBlocked: false,
    };

    return browser;
  }

  private static CreateWhatsAppBrowser(): BrowserInformation {
    const browser: BrowserInformation = {
      browser: 'WhatsApp',
      browserIcon: '32x32_redcross.png',
      deviceType: 'WhatsApp',
      deviceTypeIcon: 'empty.svg',
      os: 'Unknown',
      version: '',
      webrtcBlocked: true,
    };

    return browser;
  }

  private static CreateSmsBrowser(): BrowserInformation {
    const browser: BrowserInformation = {
      browser: 'SMS',
      browserIcon: '32x32_redcross.png',
      deviceType: 'SMS',
      deviceTypeIcon: 'empty.svg',
      os: 'Unknown',
      version: '',
      webrtcBlocked: true,
    };

    return browser;
  }

  private static CreateMessengerBrowser(): BrowserInformation {
    const browser: BrowserInformation = {
      browser: 'Messenger',
      browserIcon: '32x32_redcross.png',
      deviceType: 'Messenger',
      deviceTypeIcon: 'empty.svg',
      os: 'Unknown',
      version: '',
      webrtcBlocked: true,
    };

    return browser;
  }

  public static UnknownBrowser(browserInfo: BrowserInformation): boolean {
    return browserInfo && (browserInfo.browser == "Unknown");
  }

  public static getBrowserInfo(browser: string): BrowserInformation {
    if (browser === null) {
      return Browsers.CreateUnknownBrowser();
    }

    const lowerCaseUA = browser.toLowerCase();
    if (Browsers.IsAndroidSdk(lowerCaseUA)) {
      return Browsers.ParseAndroidUA(lowerCaseUA);
    } else if (Browsers.IsIosSdk(lowerCaseUA)) {
      return Browsers.ParseIosUA(lowerCaseUA);
    } else if (Browsers.IsWhatsApp(lowerCaseUA)) {
      return Browsers.CreateWhatsAppBrowser();
    } else if (Browsers.IsTizenSdk(lowerCaseUA)) {
      return Browsers.ParseTizenUA(lowerCaseUA);
    } else if (Browsers.IsSms(lowerCaseUA)) {
      return Browsers.CreateSmsBrowser();
    } else if (Browsers.IsMessenger(lowerCaseUA)) {
      return Browsers.CreateMessengerBrowser();
    } else {
      return Browsers.ParseGeneralUA(lowerCaseUA);
    }
  }

  private static splitSdkVersionFromUA(ua: string): string {
    return ua.split('sdkv')[1];
  }

  private static IsAndroidSdk(ua: string): boolean {
    return ua && ua.indexOf('v24android_sdkv') >= 0;
  }

  private static IsWhatsApp(ua: string): boolean {
    return ua && ua == 'whatsapp';
  }

  private static IsSms(ua: string): boolean {
    return ua && ua == 'sms';
  }

  private static IsMessenger(ua: string): boolean {
    return ua && ua == 'messenger';
  }

  private static ParseAndroidUA(ua: string): BrowserInformation {
    const browser: BrowserInformation = {
      browser: 'android sdk',
      browserIcon: 'android_sdk.png',
      deviceType: 'android sdk',
      deviceTypeIcon: 'android_sdk.png',
      os: 'Android',
      version: Browsers.splitSdkVersionFromUA(ua),
      webrtcBlocked: false,
    };

    return browser;
  }

  private static IsIosSdk(ua: string): boolean {
    return ua && ua.indexOf('v24ios_sdkv') >= 0;
  }

  private static ParseIosUA(ua: string): BrowserInformation {
    const browser: BrowserInformation = {
      browser: 'ios sdk',
      browserIcon: 'apple_sdk.png',
      deviceType: 'ios sdk',
      deviceTypeIcon: 'apple_sdk.png',
      os: 'iOS',
      version: Browsers.splitSdkVersionFromUA(ua),
      webrtcBlocked: false,
    };

    return browser;
  }

  private static IsTizenSdk(ua: string): boolean {
    return ua && ua.indexOf('v24tizen_sdkv') >= 0;
  }

  private static ParseTizenUA(ua: string): BrowserInformation {
    const browser: BrowserInformation = {
      browser: 'tizen sdk',
      browserIcon: 'tizen_sdk.png',
      deviceType: 'tizen sdk',
      deviceTypeIcon: 'tizen_sdk.png',
      os: 'Tizen',
      version: Browsers.splitSdkVersionFromUA(ua),
      webrtcBlocked: false,
    };

    return browser;
  }

  private static ParseWebkitBrowser(ua: string, parserResult: UAParser.IResult): void {
    if (Browsers.IsWebkitFacebook(ua)) {
      parserResult.browser.name = 'iOS Facebook';
    } else if (Browsers.IsWebkitChrome(ua)) {
      parserResult.browser.name = 'iOS Chrome';
    } else if (Browsers.IsWebkitFirefox(ua)) {
      parserResult.browser.name = 'iOS Firefox';
    } else if (Browsers.IsWebViewInstagram(ua)) {
      parserResult.browser.name = 'iOS Instagram';
    }
  }

  private static ParseChromeWebView(ua: string, parserResult: UAParser.IResult): void {
    if (Browsers.IsWebViewInstagram(ua)) {
      parserResult.browser.name = 'Instagram';
    }
  }

  private static IsWebkitFacebook(ua: string): boolean {
    // Can't find specific info but this seems to be what people online say and I have observered.
    return ua.indexOf('fban') > -1;
  }

  private static IsWebkitFirefox(ua: string): boolean {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent/Firefox#firefox_for_ios
    return ua.indexOf("fxios") > -1;
  }

  private static IsWebkitChrome(ua: string): boolean {
    // https://developer.chrome.com/docs/multidevice/user-agent/#chrome_for_ios_user_agent
    return ua.indexOf("crios") > -1;
  }

  private static IsWebViewInstagram(ua: string): boolean {
    return ua.indexOf("instagram") > -1;
  }

  private static ParseGeneralUA(ua: string): BrowserInformation {
    Browsers.parser.setUA(ua);
    const result = Browsers.parser.getResult();

    if (result.engine.name === 'webkit') {
      Browsers.ParseWebkitBrowser(ua, result);
    } else if (result.browser.name === 'chrome WebView') {
      Browsers.ParseChromeWebView(ua, result);
    }

    const browserName = result.browser.name ? result.browser.name : 'Unknown';
    const deviceType = result.device.type == null ? 'desktop' : result.device.type;
    const icon = this.ICON_LOOKUP.get(browserName.toLowerCase());
    const osName = result.os.name || "";

    const browser: BrowserInformation = {
      browser: browserName,
      browserIcon: icon ? icon : '32x32_redcross.png',
      deviceType: deviceType,
      deviceTypeIcon: deviceType + '.svg',
      os: result.os.name == null ? 'Unknown' : result.os.name,
      version: result.browser.version == null ? '' : result.browser.version,
      webrtcBlocked: Browsers.WebrtcBlocked(browserName, osName),
    };

    return browser;
  }
}
