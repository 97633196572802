<div class="container-fluid border-bottom py-2">
  <div class="row d-flex align-items-center">
    <div class="col-2 px-0">
      <app-user-heartbeat #heartbeat [engagementVisitor]="visitor" ></app-user-heartbeat>
    </div>
    <div class="px-0 d-flex flex-column" [class.col-6]="hasVisibleMenuItems()" [class.col-10]="!hasVisibleMenuItems()">
      <p class="text-capitalize">{{ name }}</p>
      <app-engagement-join-row-status [isPrimary]="isPrimaryCustomer()" [isPresenter]="false" [canAV]="engagement?.canHaveAV" [userCanSeeMe]="visitor?.userCanSeeMe$ | async" [userCanHearMe]="visitor?.userCanHearMe$ | async" [iCanHearUser]="visitor?.iCanHearUser$ | async"></app-engagement-join-row-status>
    </div>
    <div class="col-4 px-0" *ngIf="hasVisibleMenuItems()">
      <div class="d-flex flex-row flex-grow-1 justify-content-end">
        <p-button severity="secondary" (onClick)="updateMenuItems();menu.toggle($event)" [rounded]="true"
                  type="button">
          <fa-icon [icon]="faEllipsisV"></fa-icon>
        </p-button>

        <p-menu appendTo="body" #menu [model]="menuItems" [popup]="true">
          <ng-template pTemplate="item" let-item>
            <div class="d-flex flex-row align-items-center gap-2 py-2 px-1" role="button">
              <i [class]="item.icon + ' font-size-2'"></i>
              <p class="text-truncate flex-grow-1 font-size-1">{{item.label}}</p>
              <p-inputSwitch  *ngIf="item.state && item.state?.toggled !== undefined" [ngModel]="item.state?.toggled" (onChange)="item.command($event)" [disabled]="item.disabled"></p-inputSwitch>
            </div>
          </ng-template>
        </p-menu>
      </div>
    </div>
  </div>
</div>
