<div #icon class="w-100 h-100 center-icon" role="button"
     (click)="toggleOverlay($event)"
     [ngClass]="getColorClass()">
  <ng-container *ngIf="engagementVisitor?.deviceType$ | async as deviceType; else loading">
    <fa-icon [pTooltip]="getTooltipText()" [fixedWidth]="true"
             [icon]="getIcon(deviceType)"></fa-icon>
  </ng-container>
  <ng-template #loading>
    <fa-icon [icon]="DEFAULT_ICON" [fixedWidth]="true" animation="spin"></fa-icon>
  </ng-template>
</div>
<p-overlayPanel #op styleClass="sidebar-panel-max" showCloseIcon="true" [style]="{'left': overlayPositionLeft + 'px', 'top': overlayPositionTop + 'px'}" appendTo="body">
  <div class="font-size-0 mt-4 overlay-content">
    <app-visitor-details class="d-block overflow-auto"
                         [showLabels]="false"
                         [fixedTable]="false"
                         [page]="currentPage"
                         [location]="visitorDetails?.Location"
                         [useragent]="visitorDetails?.Browser"
                         [ip]="visitorDetails?.IPAddress"
                         [referrer]="visitorDetails?.Referrer"
                         [organisation]="visitorDetails?.Org">
    </app-visitor-details>
    <table class="table table-fixed table-borderless text-center">
      <thead>
      <tr>
        <th></th>
        <th><p class="text-break">{{ "CUSTOMERAVCONTROL_LABEL_CAMERASTATUS"  | Translate : "Camera Status" }}</p></th>
        <th><p class="text-break">{{ "CUSTOMERAVCONTROL_LABEL_MICROPHONESTATUS"  | Translate : "Customer Microphone" }}</p></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{ "CUSTOMERAVCONTROL_LABEL_ACCESSGRANTED"  | Translate : "Access Granted" }}</td>
        <td>
          <fa-icon [fixedWidth]="true"
                   [class]="visitorState?.cameraAccessGranted ? 'surface-green-text' : 'surface-red-text'"
                   [icon]="visitorState?.cameraAccessGranted ? faCircleCheck : faTimesCircle"></fa-icon>
        </td>
        <td>
          <fa-icon [fixedWidth]="true"
                   [class]="visitorState?.micAccessGranted ? 'surface-green-text' : 'surface-red-text'"
                   [icon]="visitorState?.micAccessGranted ? faCircleCheck : faTimesCircle"></fa-icon>
        </td>
      </tr>
      <tr>
        <td>{{ "CUSTOMERAVCONTROL_LABEL_ALREADYINUSE"  | Translate : "Already In Use" }}</td>
        <td>
          <fa-icon [fixedWidth]="true"
                   [class]="visitorState?.cameraAlreadyInUse ? 'surface-green-text' : 'surface-red-text'"
                   [icon]="visitorState?.cameraAlreadyInUse ? faCircleCheck : faTimesCircle"></fa-icon>
        </td>
        <td>
          <fa-icon [fixedWidth]="true"
                   [class]="visitorState?.micAlreadyInUse ? 'surface-green-text' : 'surface-red-text'"
                   [icon]="visitorState?.micAlreadyInUse ? faCircleCheck : faTimesCircle"></fa-icon>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</p-overlayPanel>
