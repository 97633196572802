import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { OnlineService } from '../../../services/online.service';
import { Features, FeatureService } from '../../../services/feature-service/feature.service';
import {CustomerSizes, PanelSize} from '../../../services/engagement';

@Component({
  selector: 'app-control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.scss'],
})
class ControlBarComponent implements OnInit, OnDestroy {

  public panelSize: PanelSize = PanelSize.Normal;
  public camera$: Observable<MediaDeviceInfo>;
  public mic$: Observable<MediaDeviceInfo>;
  public speaker$: Observable<MediaDeviceInfo>;
  public videoUnavailable: boolean;

  public panelSizeFaked: CustomerSizes = {
    size: this.panelSize,
    panelDimensions: '0,0,0,0',
    browserHeight: 0,
    browserWidth: 0
  };

  constructor(
    private onlineService: OnlineService,
    private featureService: FeatureService,
  ) {
    this.camera$ = this.onlineService.selectedCamera.asObservable();
    this.mic$ = this.onlineService.selectedMic.asObservable();
    this.speaker$ = this.onlineService.selectedSpeaker.asObservable();
  }

  ngOnInit() {
    this.videoUnavailable = this.featureService.has(Features.NO_VIDEO);
  }

  ngOnDestroy(): void {
  }

  onSelectCamera(newCamera: MediaDeviceInfo) {
    this.onlineService.selectedCamera.next(newCamera);
  }

  onSelectMic(newMic: MediaDeviceInfo) {
    this.onlineService.selectedMic.next(newMic);
  }

  onSelectSpeaker(newSpeaker: MediaDeviceInfo) {
    this.onlineService.selectedSpeaker.next(newSpeaker);
  }

  onChangePanelSize($event: PanelSize) {
    this.panelSize = $event;

    this.panelSizeFaked = {
      size: this.panelSize,
      panelDimensions: '0,0,0,0',
      browserHeight: 0,
      browserWidth: 0
    };
  }
}

export { ControlBarComponent };
