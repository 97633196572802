import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {CommunicationMode, Engagement, EngagementState, PanelSize} from '../../../../services/engagement';
import {OnlineService} from '../../../../services/online.service';
import {Features, FeatureService} from '../../../../services/feature-service/feature.service';
import {EngagementAsset} from '../../../../classes/EngagementAssets';
import {CameraDirection} from '../../../../classes/camera-direction';
import {CallType} from '../../../../enums/call-type.enum';
import {EngagementMode} from '../../../../enums/engagement-mode';

@Component({
  selector: 'app-engagement-control-bar',
  templateUrl: './engagement-control-bar.component.html',
  styleUrls: ['./engagement-control-bar.component.scss']
})
export class EngagementControlBarComponent implements OnInit, OnDestroy {
  public EngagementState = EngagementState;
  public EngagementMode = EngagementMode;

  @Input() engagement: Engagement;
  @Input() isMobile: boolean;
  @Input() isMobileOrTablet: boolean;

  @Input() useCoBrowse: boolean = true;
  @Input() useCoView: boolean = false;
  @Input() useSharing: boolean = false;
  @Input() useAppView: boolean = false;
  @Input() useCoFormFillSharing: boolean = false;

  @Input() canShare: boolean = false;
  @Input() canSecureShare: boolean = false;
  @Input() canAppView: boolean = false;
  @Input() canCoFormFillShare: boolean = false;

  @Output() switchCoBrowse = new EventEmitter<boolean>();
  @Output() switchCoView = new EventEmitter<boolean>();
  @Output() switchSharing = new EventEmitter<boolean>();
  @Output() switchAppView = new EventEmitter<boolean>();
  @Output() switchCoFormFillSharing = new EventEmitter<boolean>();

  @Output() selectedAsset = new EventEmitter<EngagementAsset>();
  @Output() changeCameraDirection = new EventEmitter<CameraDirection>();

  public camera$: Observable<MediaDeviceInfo>;
  public mic$: Observable<MediaDeviceInfo>;
  public speaker$: Observable<MediaDeviceInfo>;

  private hasNoVideoFeature: boolean = false;
  private hasAudioMode: boolean = false;

  public readonly canCoBrowse:boolean = false;

  public get videoUnavailable(): boolean {
    return this.engagement.visitor.callType === CallType.TextDowngrade || this.hasNoVideoFeature;
  }

  public get audioAvailable(): boolean {
    return this.engagement.visitor.callType === CallType.TextDowngrade || this.hasAudioMode;
  }

  public get showAppSize(): boolean {
    return !(this.engagement && this.engagement.engagementMode.getValue() != EngagementMode.UNKNOWN && this.engagement.panelFullSize.getValue());
  }

  CommunicationMode = CommunicationMode; // export for the UI
  CallType = CallType;

  constructor(
    private readonly onlineService: OnlineService,
    private readonly featureService: FeatureService
  ) {
    this.canCoBrowse = this.featureService.has(Features.COBROWSE);
  }

  ngOnInit() {
    this.camera$ = this.onlineService.selectedCamera.asObservable();
    this.mic$ = this.onlineService.selectedMic.asObservable();
    this.speaker$ = this.onlineService.selectedSpeaker.asObservable();
    this.hasNoVideoFeature = this.featureService.has(Features.NO_VIDEO);
    this.hasAudioMode = this.featureService.has(Features.AUDIO_MODE);
  }

  ngOnDestroy(): void {
  }

  onSelectCamera(newCamera: MediaDeviceInfo) {
    this.onlineService.selectedCamera.next(newCamera);
  }

  onSelectMic(newMic: MediaDeviceInfo) {
    this.onlineService.selectedMic.next(newMic);
  }

  onSelectSpeaker(newSpeaker: MediaDeviceInfo) {
    this.onlineService.selectedSpeaker.next(newSpeaker);
  }

  onSwitchCoBrowse(val: boolean){
    this.switchCoBrowse.emit(val);
  }

  onSwitchCoView(val: boolean){
    this.switchCoView.emit(val);
  }

  onSwitchSharing(val: boolean){
    this.switchSharing.emit(val);
  }

  onSwitchCoFormFillSharing(val: boolean) {
    this.switchCoFormFillSharing.emit(val);
  }

  selectAsset(asset: EngagementAsset) {
    this.selectedAsset.emit(asset);
  }

  onSwitchAppView($event: boolean) {
    this.switchAppView.emit($event);
  }

  onChangePanelSize($event: PanelSize) {
    this.engagement.setPanelSize($event);
  }

  panelPositionChanged($event: [string, string]) {

    if (this.isMobileOrTablet) {
      this.engagement.positionVideo($event[0], $event[1]);
    } else {
      this.engagement.setPanelPosition($event[0], $event[1]);
    }
  }

  onChangeCustomerVideo($event: boolean) {
    this.engagement.startCamera($event, this.engagement.primaryCustomer.getValue());
  }

  onSwitchTextVisible($event: boolean) {
    this.engagement.setTextVisible($event);
  }

  onChangeCustomerMic($event: boolean) {
    this.engagement.startMic($event, this.engagement.primaryCustomer.getValue());
  }

  onChangeSpeakerVolume($event: number) {
    this.engagement.setSpeakerVolume($event);
  }

  onChangeCustomerCog($event: boolean) {
    this.engagement.remotePanelShowDevices($event, this.engagement.primaryCustomer.getValue());
  }

  onSwitchToTextChat() {
    this.engagement.switchToTextMode();
  }

  onSwitchToAudioChat() {
    this.engagement.switchToAudioMode();
  }

  onSwitchToVideoChat() {
    this.engagement.switchToVideoMode();
  }
}
