import Guid from '../classes/Guid';
import {Connection, hubConnection, Options, Proxy} from '../../third-party/signalr-no-jquery';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {LoggingService} from './logging.service';
import {FeedType} from '../enums/multipeer/feed-type.enum';
import {CameraDirection} from '../classes/camera-direction';
import { EngagementMode } from '../enums/engagement-mode';
import {StatusFlag} from "../enums/status-flag.enum";
import {EngagementHubVisitor} from "./engagement-visitor";

export const enum EngagementServiceEvents {
  ChatMessage,
  PrivateChatMessage,
  SystemMessage,
  VisitorLeft,
  VisitorTyping,
  RoomUpdate,
  VisitorUpdate,
  StreamUpdate,
  WebrtcSignallingUpdate,
  TransferRejected,
  TransferAccepted,
  InviteRejected,
  InviteAccepted,
  AgentEndedCall,
  AgentLock,
  Kicked,
  CRMUpdated,
  DomSyncMessage,
  DomSyncCommand,
  AgentAssistAnswered,
  SwitchedCustomer,
  ChatSuggestion,
  AppViewStartRequest,
  VisitorNavigation,
  Disconnected,
  UserVerified,
  UserConsentOtp
}

export interface UserVerifiedEvent {
  type: EngagementServiceEvents.UserVerified;
  verified: boolean;
  username: string;
}

export interface UserConsentOtpEvent {
  type: EngagementServiceEvents.UserConsentOtp;
  consent: boolean;
  username: string;
  to: string;
}

export interface DisconnectedEvent {
  type: EngagementServiceEvents.Disconnected,
  reconnecting: boolean,
}

export interface ChatMessageEvent {
  type: EngagementServiceEvents.ChatMessage;
  message: HubTextMessage;
  fromPreviousChat: boolean;
}

export interface PrivateChatMessageEvent {
  type: EngagementServiceEvents.PrivateChatMessage;
  message: HubTextMessage;
}

interface SystemMessageEvent {
  type: EngagementServiceEvents.SystemMessage;
  message: EngagementControlMessage;
}

interface VisitorLeftEvent {
  type: EngagementServiceEvents.VisitorLeft;
  visitor: EngagementHubVisitor;
}

interface AgentEndedCall {
  type: EngagementServiceEvents.AgentEndedCall;
}

interface VisitorTypingEvent {
  type: EngagementServiceEvents.VisitorTyping;
  message: HubTextMessage;
}

interface RoomUpdateEvent {
  type: EngagementServiceEvents.RoomUpdate;
  room: Room;
}

interface VisitorUpdateEvent {
  type: EngagementServiceEvents.VisitorUpdate;
  visitor: EngagementHubVisitor;
}

interface StreamUpdateEvent {
  type: EngagementServiceEvents.StreamUpdate;
  stream: StreamState;
}

interface WebrtcSignallingEvent {
  type: EngagementServiceEvents.WebrtcSignallingUpdate;
  message: any;
}

interface TransferRejectedEvent {
  type: EngagementServiceEvents.TransferRejected;
  operatorUsername: string;
  reason: string;
}

interface TransferAcceptedEvent {
  type: EngagementServiceEvents.TransferAccepted;
  operatorUsername: string;
}

interface InviteRejectedEvent {
  type: EngagementServiceEvents.InviteRejected;
  operatorUsername: string;
  reason: string;
}

interface InviteAcceptedEvent {
  type: EngagementServiceEvents.InviteAccepted;
  operatorUsername: string;
}

interface KickedEvent {
  type: EngagementServiceEvents.Kicked;
}

export interface AgentLockEvent {
  type: EngagementServiceEvents.AgentLock;
  isLocked: boolean;
}

export interface CRMUpdateEvent {
  type: EngagementServiceEvents.CRMUpdated;
  crmData: VisitorCRMData;
}

export interface DomSyncMessageEvent {
  type: EngagementServiceEvents.DomSyncMessage;
  message: string;
}

export interface DomSyncCommandEvent {
  type: EngagementServiceEvents.DomSyncCommand;
  message: string;
}

export interface AgentAssistEvent {
  type: EngagementServiceEvents.AgentAssistAnswered;
}

export interface SwitchCustomerEvent {
  type: EngagementServiceEvents.SwitchedCustomer;
  switchedIDs: SwitchCustomerIds;
}

export interface ChatSuggestionEvent {
  type: EngagementServiceEvents.ChatSuggestion;
  message: string;
}

export interface AppViewStartEvent {
  type: EngagementServiceEvents.AppViewStartRequest;
}

export interface VisitorNavigationEvent {
  type: EngagementServiceEvents.VisitorNavigation;
  navigating: boolean;
}

export type EngagementServiceEvent = ChatMessageEvent
  | SystemMessageEvent
  | AgentEndedCall
  | VisitorLeftEvent
  | VisitorTypingEvent
  | RoomUpdateEvent
  | VisitorUpdateEvent
  | StreamUpdateEvent
  | WebrtcSignallingEvent
  | TransferRejectedEvent
  | TransferAcceptedEvent
  | InviteRejectedEvent
  | InviteAcceptedEvent
  | KickedEvent
  | AgentLockEvent
  | CRMUpdateEvent
  | DomSyncMessageEvent
  | DomSyncCommandEvent
  | PrivateChatMessageEvent
  | AgentAssistEvent
  | SwitchCustomerEvent
  | ChatSuggestionEvent
  | AppViewStartEvent
  | VisitorNavigationEvent
  | DisconnectedEvent
  | UserVerifiedEvent
  | UserConsentOtpEvent;

enum EngagementHubCallback {
  ConnectionComplete = 'ConnectionComplete',
  RoomState = 'RoomState',
  ChatMessage = 'ChatMessage',
  ChatMessages = 'ChatMessages',
  PrivateChatMessage = 'PrivateChatMessage',
  PrivateChatMessages = 'PrivateChatMessages',
  SystemMessage = 'SystemMessage',
  VisitorLeftEngagement = 'VisitorLeftEngagement',
  ScreenshareMessage = 'ScreenshareMessage',
  UpdateCRM = 'UpdateCRM',
  LockRoomAgents = 'LockRoomAgents',
  UnlockRoomAgents = 'UnlockRoomAgents',
  AgentEndsCall = 'AgentEndsCall',
  Kicked = 'Kicked',
  InviteRejected = 'InviteRejected',
  InviteAccepted = 'InviteAccepted',
  DomSyncMessage = 'DomSyncMessage',
  DomSyncCommand = 'DomSyncCommand',
  TransferRejected = 'TransferRejected',
  TransferAccepted = 'TransferAccepted',
  UpdateVisitorTyping = 'UpdateVisitorTyping',
  UpdateVisitor = 'UpdateVisitor',
  UpdateParticipant = 'UpdateParticipant',
  RecvStreamState = 'RecvStreamState',
  SwitchCustomer = 'SwitchCustomer',
  ChatSuggestion = 'ChatSuggestion',
  WebRTCSignallingMessage = 'WebRTCSignallingMessage',
  AgentAssistAnswered = 'HelpRequestAccepted',
  RequestAppViewingStarts = 'RequestAppViewingStarts',
  VisitorNavigation = 'VisitorNavigation',
  UserVerified = 'UserVerified',
  UserConsentOtp = 'UserConsentOtp'
}


export enum EngagementHubMethod {
  InitialRoomState = 'InitialiseRoomState',
  GetRoomState = 'GetRoomState',
  UpdateAgentAndGetRoomState = 'UpdateAgentAndGetRoomState',
  SetRoomProperty = 'SetRoomProperty',
  GetChatMessagesSince = 'GetChatMessagesSince',
  GetPrivateChatMessagesSince = 'GetPrivateChatMessagesSince',
  SendWebrtcMessage = 'SendWebRTCSignallingMessage',
  SendSystemMessageToVisitors = 'SendSystemMessageToVisitors',
  SendChatMessage = 'SendChatMessage',
  SendFileUploadMessage = 'SendFileUploadMessage',
  SendPrivateChatMessage = 'SendPrivateChatMessage',
  AgentEndsCall = 'AgentEndsCall',
  TransferRequest = 'TransferRequest',
  TransferCancel = 'TransferCancel',
  JoinRequest = 'InviteJoin',
  JoinCancel = 'InviteCancel',
  Kick = 'Kick',
  ReceiveTranslation = 'ReceiveTranslation',
  LockRoomAgents = 'LockRoomAgents',
  UnlockRoomAgents = 'UnlockRoomAgents',
  SaveCRMData = 'SaveCRMData',
  UpdateCustomerName = 'UpdateCustomerName',
  DomSyncCommand = 'DomSyncCommand',
  AgentRequestsHelp = 'AgentRequestsHelp',
  AgentCancelHelp = 'AgentCancelHelp',
  SwitchCustomer = 'SwitchCustomer',
  ChooseFeed = "ChooseFeed",
  ChangeVideoSize = "ChangeVideoSize",
  ChangePanelSize = "ChangePanelSize",
  SetConnectionInfo = "SetConnectionInfo",
  SetPanelPosition = "SetPanelPosition",
  ShowTextPanel = "ShowTextPanel",
  ShowPanel = "ShowPanel",
  AgentVideoUnDocked = "AgentVideoUnDocked",
  ChangeEngagementMode = "ChangeEngagementMode",
  LogAgentAction = 'LogAgentAction',
  StartCamera = 'StartCamera',
  StartMic = 'StartMic',
  RemotePanelShowDevices = 'RemotePanelShowDevices',
  ConfirmOtp = 'ConfirmOtp',
  SendOtp = 'SendOtp'
}


export enum ControlMessageType {
  StartChat = 3,
  EndChat = 4,
  ShowPage = 5,
  StartSharing = 6,
  LoadFlashPresentation = 8,
  UpdateCustomerName = 9,
  ChatMessage = 14,
  PauseCall = 18,
  ResumeCall = 19,
  OperatorAccepts = 20,
  //SwitchCustomer = 21,
  PlayPresentation = 22,
  PausePresentation = 23,
  GoToSlide = 24,
  GoToStep = 25,
  RejectCall = 26,
  SetMicGain = 27,
  SetSpeakerVolume = 28,
  MakePanelSmall = 35,
  MakePanelNormal = 36,
  MakePanelBig = 37,
  TextChatMode = 38,
  VideoMode = 39,
  PositionComsPanel = 40,
  MoveComsPanel = 41,
  AudioOnlyMode = 42,
  ShowText = 44,
  HideText = 45,
  OpStartInternalApp = 46,
  OpStopInternalApp = 47,
  OpTyping = 48,
  OpDeletedTyping = 49,
  ShowPanel = 52,
  HidePanel = 53,
  OpDeviceShow = 54,
  OpDeviceHide = 55,
  ShowVideo = 56,
  HideVideo = 57,
  PositionVideo = 58,
  ToggleUpgrade = 59,
  ViewOpVideo = 60,
  HideOpVideo = 61,
  HearOp = 62,
  MuteOp = 63,
  StartCamera = 64,
  StopCamera = 65,
  StartMic = 66,
  StopMic = 67,
  RemoveBrowser = 69,
  StopScreenShare = 70,
  StartAppView = 71,
  StopAppView = 72,
  DomSyncStart = 82,
  DomSyncCancel = 83,
  TransferringUser = 90,
  CancelTransferringUser = 91,
  TransferChat = 92,
  SecureChatEnable = 93,
  SecureChatDisable = 94,
  UserChatMessage = 114,
  LogAgentAction = 330,
}

export class StartRoomState {
  type: 'initial'

  public SyncBrowser: boolean = false;
  public SyncDom: boolean = false;
  public CurrentPage: string = '';
  public BrowserMode: string = '';
  public Sharing: boolean = false;
  public SharingMode: string = '';
  public OnHold: boolean = false;
  public ChatOnly: boolean = false;
  public CallType: string = 'WebRTC';
  public EmulatingDevice: boolean = false;
  public UpgradeInProgress: boolean = false;
  public AuthenticatedIceUrl: string = '';

  public AgentAssistAutosendMessage: boolean = false;
  public AgentAssistBotGetSuggestions: boolean = false;
  public AgentAssistBotEnabledForRoom: boolean = false;
}

export class TransferRoomState {
  type: 'transfer'
}

export class JoinRoomState {
  type: 'join'
}

export class SupervisorRoomState {
  type: 'supervisor'
}

export class PresenterRoomState {
  type: 'presenter'
}

export class RejoinRoomState {
  type: 'rejoin'
}

export type InitialRoomState = StartRoomState | TransferRoomState | JoinRoomState | SupervisorRoomState | PresenterRoomState | RejoinRoomState;

export class StreamState {
  public StreamName = '';
  public CameraOn = false;
  public AudioOn = false;
  public Ready = false;
  public UnDocked = true;
}

class AgentState {
  public Message = '';
  public IsTyping = false;

  public Stream = new StreamState();
}

export class Agent {
  public UserName: string;
  public SiteName: string;
  public ListType: number;
  public Photo: string;
  public Landscape: string;
  public NickName: string;
  public LastUpdateTime: Date;
  public State: AgentState;
}

class Presentation {
  public URL: string;
  public SlideIndex: number;
  public StepIndex: number;
}

export class SDK {
  public shouldBrowserBeVisible: boolean;
  public isBrowserVisible: boolean;
  public shouldBeSendingScreen: boolean;
  public isSendingScreen: boolean;
  public inForeground: boolean;
  public screenWidth: number;
  public screenHeight: number;
  public browserWidth: number;
  public browserHeight: number;
  public sharingWidth: number;
  public sharingHeight: number;
  public cameraOrientation: number;
  public cameraDirection: string;
  public cameraWidth: number;
  public cameraHeight: number;
  public viewAppMode: number;
}

export class Room {
  public Id: string;
  public SessionId: string;
  public CurrentTime: string;

  public StartDateTime: Date;
  public EndDateTime: Date;
  public SyncBrowser: boolean;
  public SyncDom: boolean;
  public CurrentPage: string;
  public BrowserMode: string;
  public Sharing: boolean;
  public SharingMode: string;
  public OnHold: boolean;
  public ChatOnly: boolean;
  public CallType: string;
  public EmulatingDevice: boolean;
  public UpgradeInProgress: boolean;
  public PrimaryAgent: string;
  public PresentingAgent: string;
  public PrivateChatEnabled: boolean;

  public AuthenticatedIceUrl: string;

  public AgentAssistBotAutosendMessage: boolean;
  public AgentAssistBotGetSuggestions: boolean;
  public AgentAssistBotEnabledForRoom: boolean;

  public Presentation: Presentation;

  public Agents: Agent[] = [];
  public Visitors: EngagementHubVisitor[] = [];

  public FeedLocation: string;
  public CurrentFeedType: FeedType;
  public CurrentFeedId: number;

  public RoomNumber: number;
  public PrimaryCustomer: string;
}

export class HubJsonTextMessage {
  public Id = -1;
  public TimeStamp: string = new Date().toString();
  public Message = '';
  public SenderName = '';
  public SenderId = '';
  public SenderIsAgent = false;
  public OriginalMessage = '';
  public MessageCulture = '';
  public IsVerified: boolean = false;
}

export class HubTextMessage {
  public Id = -1;
  public TimeStamp: Date = new Date();
  public Message = '';
  public SenderName = '';
  public SenderId = '';
  public SenderIsAgent = false;
  public OriginalMessage = '';
  public MessageCulture = '';
  public IsVerified: boolean = false;
}

export class EngagementControlMessage {
  public MessageType: ControlMessageType;
  public Flag = '';
  public Message = '';
  public Timestamp = new Date();
  public Sender = '';
}

export enum VerifyStatuses {
  Pending = 'pending',
  Approved = 'approved',
  Cancelled = 'canceled',
  MaxAttemptsReached = 'max_attempts_reached',
  Deleted = 'deleted',
  Failed = 'failed',
  Expired = 'expired',
  Error = 'error',
}

export interface IEngagementHubVisitorState {
  panelSize: number;
  videoSize: number;
  clientSizeX: number;
  clientSizeY: number;
  textVisible: boolean;
  videoVisible: boolean;
  cameraEnabled: boolean;
  hasCameraCapabilities: boolean;
  micEnabled: boolean;
  hasMicrophoneCapabilities: boolean;
  viewingOperatorStream: boolean;
  hearingOperatorStream: boolean;
  micGain: number;
  speakerVolume: number;
  devicesVisible: boolean;
  flag: number;
  callPaused: boolean;
  isSharing: boolean;
  isPresenting: boolean;
  cobrowsingEnabled: boolean;
  panelPositionAndSize: string;
  chatOnly: boolean;
  communicationMode: number;
  frontCameraOn: boolean;
  mobileChatType: string;
  deviceCameraRotation: number;
  currentPage: string;
  upgradeInProgress: boolean;
  panelVisible: boolean;
  cameraAccessGranted: boolean;
  cameraAlreadyInUse: boolean;
  micAccessGranted: boolean;
  micAlreadyInUse: boolean;
  micActivityLevel: number;
  panelFullSize: boolean;
  engagementMode: string;
  SDK: SDK;
}

class ScreenShareMessage {
  public from = '';
  public to = '';
  public type = '';
  public message = '';
}

export class VisitorCRMData {
  public Data = '';
}

interface TransferAccept {
  TransferringAgentUserName: string;
}

interface VerifyResponse {
  From: string;
  Verified: boolean;
}

interface VerifyConsentResponse {
  From: string;
  To: string;
  Consent: boolean;
}

interface TransferRejection {
  TransferringAgentUserName: string;
  Reason: string;
}

interface InviteRejection {
  InvitedAgentUserName: string;
  Reason: string;
}

interface InviteAccept {
  InvitedAgentUserName: string;
}

interface KickingAgent {
  KickingAgentUsername: string;
}

export class SwitchCustomerIds {
  public CurrentUserId = '';
  public OriginalUserId = '';
}

enum SuggestionErrorCodes {
  ERROR_SUCCESS = 0,
  ERROR_UNKNOWN = -1,
}

class Suggestion {
  public Message = '';
  public Status: SuggestionErrorCodes = SuggestionErrorCodes.ERROR_UNKNOWN;
}

export class EngagementOptions {
  id: Guid;
  sessionId: Guid;
  username: string;
  sitename: string;
  connectionType: number;
  listType: number;
  nickname: string;
  photo: string;
  widephoto: string;
  feedLocation: string;
  isPresentationDevice: boolean;
}

export interface IEngagementHubService {
  isConnected: BehaviorSubject<boolean>;
  events: Subject<EngagementServiceEvent>;

  initialise(engagementOptions: EngagementOptions, initialRoomState: InitialRoomState): void;
  connect(): Observable<boolean>;
  disconnect();

  sendWebrtcMessage(message: string): void;
  sendChatMessage(message: string, messageCulture: string, originalMessage?: string): Promise<HubTextMessage>;
  sendFileUploadMessage(message: string): Promise<HubTextMessage>;
  sendPrivateChatMessage(message: string): Promise<void>;
  receiveTranslation(message: HubTextMessage): void;
  chooseFeed(peerId: string, feedType: FeedType, feedId: number, gatewayServer: string): void;
  endChat(page: string): Promise<void>;
  showPage(page: string): Promise<void>;
  startSharing(params: string): void;
  stopSharing(): Promise<void>;
  startDomSync(): Promise<void>;
  stopDomSync(): Promise<void>;
  pauseCall(pause: boolean): Promise<void>;
  controlInternalApp(start: boolean): Promise<void>;
  setMicGain(newVolume: number): Promise<void>;
  setSpeakerVolume(newVolume: number): Promise<void>;
  switchToTextMode(): Promise<void>;
  switchToAudioMode(): Promise<void>;
  switchToVideoMode(): Promise<void>;
  setPanelSizeSmall(): Promise<void>;
  setPanelSizeNormal(): Promise<void>;
  setPanelSizeBig(): Promise<void>;
  setPanelSizeHD(): Promise<void>;
  setVideoSizeSmall(): Promise<void>;
  setVideoSizeNormal(): Promise<void>;
  setVideoSizeBig(): Promise<void>;
  setVideoSizeHD(): Promise<void>;
  setPanelPosition(hAlign: string, vAlign: string): Promise<void>;
  moveComsPanel(): Promise<void>;
  positionVideo(hAlign: string, vAlign: string): Promise<void>;
  showText(show: boolean): Promise<void>;
  opTyping(): Promise<void>;
  opDeletedTyping(): Promise<void>;
  showVideo(show: boolean): Promise<void>;
  changeCameraDirection(start: boolean, cameraDirection?: CameraDirection): Promise<void>;
  startCamera(start: boolean, username: string, cameraDirection?: CameraDirection): Promise<void>;
  startMic(start: boolean, username: string): Promise<void>;
  enablePrivateChat(): Promise<void>;
  remotePanelShowDevices(start: boolean, username: string): Promise<void>;
  changeEngagementMode(newMode: EngagementMode): Promise<void>;

  lockRoom(): Promise<void>;
  unlockRoom(): Promise<void>;

  transferringInAccepted(message: string): Promise<void>;
  sendTransferOutRequest(sitename: string, transferringOperator: string, transferReason: string): Promise<void>;
  abortTransferOutRequest(sitename: string, transferringOperator: string): Promise<void>;
  sendJoinRequest(sitename: string, joiningOperator: string, invitationReason: string): Promise<void>;
  abortJoinRequest(sitename: string, joiningOperator: string): Promise<void>;
  kickAgent(operatorUsername: string): Promise<void>;
  setRoomProperty(propertyName: string, value: string): Promise<void>;
  notifyCustomerOfTransferOut(message: string): Promise<void>;
  notifyCustomerTransferWasCancelled(message: string): Promise<void>;
  saveCrmData(userGuid: string, crmData: VisitorCRMData): Promise<void>;
  updateCustomerName(userGuid: string, name: string): Promise<void>;
  sendDomSyncCommand(message: string): Promise<void>;
  cancelHelpRequest(): Promise<void>;
  requestHelp(): Promise<void>;
  switchCustomer(currentUserId: string, originalUserId: string): Promise<void>;
  setConnectionInfo(connectionInfo: ConnectionInfo): void;

  enableAgentAssistBotAutosendMessage(isBotAutoSend: boolean): Promise<void>;
  enableAgentAssistBotGetSuggestions(isBotGetSuggestions: boolean): Promise<void>;
  enableAgentAssistBotForRoom(isAgentAssistBotEnable: boolean): Promise<void>;
  showPanel(show: boolean): Promise<void>;

  startAppView(): Promise<void>;
  stopAppView(): Promise<void>;

  removeBrowser(): Promise<void>;

  hearOp(): Promise<void>;
  muteOp(): Promise<void>;

  agentVideoUnDocked(unDocked: boolean): void;
  confirmOtp(userguid: string, to: string): Promise<void>;
  sendOtp(userguid: string, to: string): Promise<string>;
}

export class ConnectionInfo {
  callType: string;
  opImage: string;
  operatorNickname: string;
  webrtcIceServer: string;
}

interface NavigationStartEvent {
  eventType: 'END';
}

interface NavigationEndEvent {
  eventType: 'START';
}

type NavigationEvent = NavigationStartEvent | NavigationEndEvent;

export class EngagementHubService implements IEngagementHubService {
  private static readonly HUB_NAME = 'engageR';
  private static readonly UPDATE_ROOM_TIMEOUT_MS = 1000;

  // The maximum reconnects are after signalr disconnection.
  // This after the 30 seconds signalr disconnection and the 30 seconds
  // reconnecting attempts. So the total time it tries to reconnect for is
  // MAX_RECONNECTION_ATTEMPTS * RECONNECT_TIMEOUT_MS + 1 minutes
  private static readonly MAX_RECONNECTION_ATTEMPTS = 10;
  private static readonly RECONNECT_TIMEOUT_MS = 6 * 1000;

  // Signalr sets the connectionSlow event at 20 seconds (1/3 of connection timeout)
  // which is too long for us to inform the user. The slow connection timeout is
  // only used to inform the user, it's not used for causing any reconnections.
  private static readonly SLOW_CONNECTION_TIMEOUT_MS: number = 10 * 1000;

  private slowConnectionTimeout: number = -1;

  readonly isConnected = new BehaviorSubject<boolean>(false);

  readonly events = new Subject<EngagementServiceEvent>();

  private connection: Connection;
  private proxy: Proxy;

  private _listeners: Map<EngagementHubCallback, (...args: any[]) => void>;

  // Room initialized by agent
  private initialised = false;

  private roomStateTimeout: any = -1;

  private username: string;
  private operatorNickname: string;
  private engagementId: Guid;
  private videoUnDocked: boolean = false;

  private initialRoomState: InitialRoomState;
  private disposing: boolean = false;

  private lastMessageId: number = -1;
  private lastPrivateMessageId: number = -1;

  private newConnectionAttemptTimeout: number = -1;

  private static createQueryString(options: EngagementOptions) {
    return 'roomId=' + encodeURIComponent(options.id.toString())
      + '&sessionGuid=' + encodeURIComponent(options.sessionId.toString())
      + '&connectionType=' + encodeURIComponent(options.connectionType.toString())
      + '&username=' + encodeURIComponent(options.username)
      + '&sitename=' + encodeURIComponent(options.sitename)
      + '&listType=' + encodeURIComponent(options.listType.toString())
      + '&name=' + encodeURIComponent(options.nickname)
      + '&photo=' + encodeURIComponent(options.photo)
      + '&landscape=' + encodeURIComponent(options.widephoto)
      + '&feedLocation=' + encodeURIComponent(options.feedLocation.toString())
      + '&isPresentationDevice=' + encodeURIComponent(options.isPresentationDevice.toString());
  }

  constructor(private logging: LoggingService) {
    this.setupListenerArray();
  }

  private setupListenerArray() {
    this._listeners = new Map();

    this._listeners.set(EngagementHubCallback.RoomState, this.onRoomState.bind(this));
    this._listeners.set(EngagementHubCallback.ChatMessage, this.onChatMessage.bind(this));
    this._listeners.set(EngagementHubCallback.PrivateChatMessage, this.onPrivateChatMessage.bind(this));
    this._listeners.set(EngagementHubCallback.ChatMessages, this.onChatMessages.bind(this));
    this._listeners.set(EngagementHubCallback.PrivateChatMessages, this.onPrivateChatMessages.bind(this));
    this._listeners.set(EngagementHubCallback.SystemMessage, this.onSystemMessage.bind(this));
    this._listeners.set(EngagementHubCallback.VisitorLeftEngagement, this.onVisitorLeftEngagement.bind(this));
    this._listeners.set(EngagementHubCallback.ScreenshareMessage, this.onScreenshareMessage.bind(this));
    this._listeners.set(EngagementHubCallback.UpdateCRM, this.onCRMStateUpdated.bind(this));
    this._listeners.set(EngagementHubCallback.LockRoomAgents, this.onLockRoomAgents.bind(this));
    this._listeners.set(EngagementHubCallback.UnlockRoomAgents, this.onUnlockRoomAgents.bind(this));
    this._listeners.set(EngagementHubCallback.AgentEndsCall, this.onAgentEndsCall.bind(this));
    this._listeners.set(EngagementHubCallback.Kicked, this.onKicked.bind(this));
    this._listeners.set(EngagementHubCallback.InviteRejected, this.onInviteRejected.bind(this));
    this._listeners.set(EngagementHubCallback.InviteAccepted, this.onInviteAccepted.bind(this));
    this._listeners.set(EngagementHubCallback.DomSyncMessage, this.onDomSyncMessage.bind(this));
    this._listeners.set(EngagementHubCallback.DomSyncCommand, this.onDomSyncCommand.bind(this));
    this._listeners.set(EngagementHubCallback.TransferRejected, this.onTransferRejected.bind(this));
    this._listeners.set(EngagementHubCallback.TransferAccepted, this.onTransferAccepted.bind(this));
    this._listeners.set(EngagementHubCallback.UpdateVisitorTyping, this.onVisitorUpdateTyping.bind(this));
    this._listeners.set(EngagementHubCallback.UpdateVisitor, this.updateVisitor.bind(this));
    this._listeners.set(EngagementHubCallback.UpdateParticipant, this.updateVisitor.bind(this));
    this._listeners.set(EngagementHubCallback.RecvStreamState, this.onStreamState.bind(this));
    this._listeners.set(EngagementHubCallback.SwitchCustomer, this.onSwitchCustomer.bind(this));
    this._listeners.set(EngagementHubCallback.ChatSuggestion, this.onGetSuggestion.bind(this));
    this._listeners.set(EngagementHubCallback.WebRTCSignallingMessage, this.onWebrtcSignalling.bind(this));
    this._listeners.set(EngagementHubCallback.AgentAssistAnswered, this.onAgentAssistAnswered.bind(this));
    this._listeners.set(EngagementHubCallback.RequestAppViewingStarts, this.onRequestAppViewingStarts.bind(this));
    this._listeners.set(EngagementHubCallback.VisitorNavigation, this.onVisitorNavigation.bind(this));
    this._listeners.set(EngagementHubCallback.UserVerified, this.onVisitorVerified.bind(this));
    this._listeners.set(EngagementHubCallback.UserConsentOtp, this.onVisitorConsentOtp.bind(this));
  }

  public initialise(engagementOptions: EngagementOptions, initialRoomState: InitialRoomState) {
    const options: Options = {
      qs: EngagementHubService.createQueryString(engagementOptions),
      // logging: true,
    };

    this.username = engagementOptions.username;
    this.operatorNickname = engagementOptions.nickname;
    this.engagementId = engagementOptions.id;

    this.initialRoomState = initialRoomState;
    if (initialRoomState.type === 'initial') {
      this.initialised = false;
    } else {
      this.initialised = true;
    }

    this.connection = hubConnection(environment.engageHub, options);
    this.proxy = this.connection.createHubProxy(EngagementHubService.HUB_NAME);

    this.registerListeners();
  }

  private registerListeners() {
    let reconnectionAttempt = 0;
    this.connection.starting(() => {
      if (this.disposing) {
        this.logging.error(`Starting engagement hub connection on already disposed engagement ${this.engagementId}`);
      } else {
        this.logging.info(`Starting engagement hub connection for engagement ${this.engagementId}`);
        reconnectionAttempt = 0;
      }
    });

    this.connection.connectionSlow(() => {
      if (this.disposing) {
        this.logging.error(`Engagement hub connection slow on disposed engagement ${this.engagementId}`);
      } else {
        this.logging.warn(`Engagement hub connection slow for engagement ${this.engagementId}`);
      }
    });

    this.connection.reconnecting(() => {
      if (this.disposing) {
        this.logging.error(`Reconnecting to already disposed engagement hub connection ${this.engagementId}`);
      } else {
        this.logging.warn(`Reconnecting engagement hub connection for engagement ${this.engagementId}`);
        reconnectionAttempt++;
        this.isConnected.next(false);
        this.events.next({
          type: EngagementServiceEvents.Disconnected,
          reconnecting: true,
        });
      }
    });

    this.connection.reconnected(() => {
      if (this.disposing) {
        this.logging.error(`Reconnected to already disposed engagement hub connection ${this.engagementId}`);
      } else {
        this.logging.warn(`Reconnected engagement hub connection for engagement ${this.engagementId}`);
        reconnectionAttempt = 0;
        this.isConnected.next(true);
        this.getMessageSince(this.lastMessageId);
        this.getPrivateChatMessagesSince(this.lastPrivateMessageId);
      }
    });

    this.connection.disconnected(() => {
      if (this.disposing) {
        this.logging.info(`Disconnected from disposed engagement hub connection ${this.engagementId}`);
      } else {
        this.logging.warn(`Disconnected engagement hub connection for engagement ${this.engagementId}`);
        this.isConnected.next(false);
        reconnectionAttempt++;
        if (reconnectionAttempt > EngagementHubService.MAX_RECONNECTION_ATTEMPTS) {
          this.logging.error(`Too many reconnection to hub for engagement ${this.engagementId}`);
          this.events.next({
            type: EngagementServiceEvents.Disconnected,
            reconnecting: false,
          });
        } else {
          this.newConnectionAttempt();
          this.events.next({
            type: EngagementServiceEvents.Disconnected,
            reconnecting: true,
          });
        }
      }
    });

    this._listeners.forEach((listener, methodName) => this.proxy.on(methodName, listener));
  }

  private newConnectionAttempt() {
    if (this.newConnectionAttemptTimeout > -1) {
      this.logging.warn('Attempting to start a new connection while one is already queued.');
    } else {
      this.newConnectionAttemptTimeout = window.setTimeout(() => this.reconnect(), EngagementHubService.RECONNECT_TIMEOUT_MS);
      this.logging.warn(`New engagement hub connection attempt for ${this.engagementId} queued.`);
    }
  }

  private reconnect() {
    this.logging.warn(`Reconnecting attempt for engagement hub connection for engagement ${this.engagementId} starting.`);
    window.clearTimeout(this.newConnectionAttemptTimeout);
    this.newConnectionAttemptTimeout = -1;
    this.connection.start({ withCredentials: true, transport: 'webSockets' })
      .done((data: any) => {
        this.logging.debug('Now connected ' + data.transport.name + ', connection ID= ' + data.id);
        this.isConnected.next(true);
        this.getMessageSince(this.lastMessageId);
        this.getPrivateChatMessagesSince(this.lastPrivateMessageId);
      }).fail((error: any) => {
        this.isConnected.next(false);
        this.logging.debug('Could not connect ' + error);
    });
  }

  private unregisterListeners() {
    this._listeners.forEach((listener, methodName) => this.proxy.off(methodName, listener));
  }

  public connect(): Observable<boolean> {
    return new Observable(obs => {
      this.connection.start({ withCredentials: true, transport: 'webSockets' })
        .done((data: any) => {
          this.logging.debug('Now connected ' + data.transport.name + ', connection ID= ' + data.id);
          this.onConnected();
          this.isConnected.next(true);
          obs.next(true);
          obs.complete();
        }).fail((error: any) => {
          this.logging.debug('Could not connect ' + error);
          this.isConnected.next(false);
          obs.next(false);
          obs.complete();
      });
    });
  }

  public disconnect() {
    this.isConnected.complete();
    this.dispose();
    if (this.connection) {
      this.connection.stop();
    }
  }

  private dispose() {
    this.disposing = true;
    window.clearTimeout(this.newConnectionAttemptTimeout);
    window.clearTimeout(this.roomStateTimeout);
    window.clearTimeout(this.slowConnectionTimeout);
    if (this.proxy) {
      this.unregisterListeners();
    }
  }

  private onConnected() {
    this.logging.debug('onConnected');

    if (!this.initialised) {
      this.initialiseRoomState(this.initialRoomState as StartRoomState);
    } else {
      this.startRoomStateUpdates();
    }

    switch (this.initialRoomState.type) {
      case 'transfer':
        this.logAgentActionAndSwallow(AgentAction.JOIN_MODE_TRANSFER);
        break;
      case 'initial':
        this.logAgentActionAndSwallow(AgentAction.JOIN_MODE_INITIAL);
        break;
      case 'join':
        this.logAgentActionAndSwallow(AgentAction.JOIN_MODE_JOIN);
        break;
      case 'supervisor':
        this.logAgentActionAndSwallow(AgentAction.JOIN_MODE_SUPERVISOR);
        break;
      case 'presenter':
        this.logAgentActionAndSwallow(AgentAction.JOIN_MODE_PRESENTER);
        break;
      case 'rejoin':
        this.logAgentActionAndSwallow(AgentAction.JOIN_MODE_REJOIN);
        break;
    }

    this.getMessageSince(-1)
      .catch(err => this.logging.error("Failed to get all messages with getMessageSince(-1) when connecting."));
    this.getPrivateChatMessagesSince(-1)
      .catch(err => this.logging.error("Failed to get all private messages with getPrivateChatMessageSince(-1) when connecting."));

    // Not getting cookies currently
    // sectionCookies = getCookies();
  }

  public setConnectionInfo(connectionInfo: ConnectionInfo) {
    return this.invoke(EngagementHubMethod.SetConnectionInfo, connectionInfo)
    .catch(err => {
      if (this.disposing) {
        this.logging.debug('Error setting Connection Info, but was disconnecting so ignored.');
      } else {
        this.logging.error('Error setting Connection Info', err);
      }
    });
  }

  // todo: this needs to be made private, this is just a aide to make the testing
  // easier.
  public invoke<T>(methodName: EngagementHubMethod, ...args: any[]): Promise<T> {
    return new Promise((resolve, reject) => {
      try {
        this.proxy.invoke(methodName, ...args)
          .done((result: T) => {
            resolve(result);
          }).fail((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  private getMessageSince(lastMessageId: number): Promise<void> {
    return this.invoke(EngagementHubMethod.GetChatMessagesSince, lastMessageId);
  }

  private getPrivateChatMessagesSince(lastMessageId: number): Promise<void> {
    return this.invoke(EngagementHubMethod.GetPrivateChatMessagesSince, lastMessageId);
  }

  private initialiseRoomState(initialRoomState: StartRoomState) {
    this.invoke<void>(EngagementHubMethod.InitialRoomState, initialRoomState)
      .then(() => {
        this.initialised = true;
        this.startRoomStateUpdates();
      }).catch(err => {
        // todo: handle this.
        this.logging.error('Error connecting to hub!!!', err);
      });
  }

  private startRoomStateUpdates() {
    this.roomStateTimeout = window.setTimeout(() => this.requestUpdatedRoomState(), EngagementHubService.UPDATE_ROOM_TIMEOUT_MS);
  }

  private requestUpdatedRoomState() {
    window.clearTimeout(this.roomStateTimeout);
    window.clearTimeout(this.slowConnectionTimeout);

    if (this.disposing) {
      this.logging.warn('Room updated loop from disposed engagement hub connection.');
      return;
    }

    if (this.initialised) {
      if (this.isConnected.value) {
        this.slowConnectionTimeout = window.setTimeout(() => {
          this.logging.warn(`Slow connection timeout triggered`);
          this.isConnected.next(false);
          this.events.next({
            type: EngagementServiceEvents.Disconnected,
            reconnecting: true,
          });
        }, EngagementHubService.SLOW_CONNECTION_TIMEOUT_MS);

        this.invoke(EngagementHubMethod.GetRoomState, this.getMyState())
          .then(() => {
            this.isConnected.next(true);
          })
          .catch(err => {
            if (this.disposing) {
              this.logging.debug('Error getting room state occurred, but was disconnecting so ignored.');
            } else {
              this.logging.error('Error getting room state', err);
            }
          }).finally(() => {
            // Always clear the slow timeout. In the case of failures it would have already been triggered.
            window.clearTimeout(this.slowConnectionTimeout);
            if (!this.disposing) {
              this.startRoomStateUpdates();
            }
          });
      } else {
        this.logging.info('Attempting to get room state when not connected');
        this.startRoomStateUpdates();
      }
    } else {
      this.startRoomStateUpdates();
    }
  }

  private getMyState(): AgentState {
    const state: AgentState = new AgentState();

    state.Message = '';
    state.IsTyping = false;

    state.Stream.StreamName = this.username;
    state.Stream.UnDocked = this.videoUnDocked;
    // THESE ARE NOT USED
    state.Stream.AudioOn = true;
    state.Stream.CameraOn = true;

    // todo: Need to make sure that the webrtc part is setup before connecting
    state.Stream.Ready = true;


    return state;
  }

  private onRoomState(room: Room) {
    //this.logging.debug('onRoomState');
    const msg: RoomUpdateEvent = {
      type: EngagementServiceEvents.RoomUpdate,
      room
    };

    this.events.next(msg);
  }

  private onChatMessage(textMessage: HubJsonTextMessage, fromPreviousChat:boolean = false) {
    this.logging.debug('onChatMessage');

    const msg: ChatMessageEvent = {
      type: EngagementServiceEvents.ChatMessage,
      message: this.toHubTextMessage(textMessage),
      fromPreviousChat
    };

    this.lastMessageId = Math.max(this.lastMessageId, msg.message.Id);
    this.events.next(msg);
  }

  private onPrivateChatMessage(textMessage: HubJsonTextMessage) {
    this.logging.debug('onPrivateChatMessage');

    const msg: PrivateChatMessageEvent = {
      type: EngagementServiceEvents.PrivateChatMessage,
      message: this.toHubTextMessage(textMessage),
    };

    this.lastPrivateMessageId = Math.max(this.lastPrivateMessageId, msg.message.Id);

    this.events.next(msg);
  }

  private onChatMessages(textMessages: HubJsonTextMessage[]) {
    this.logging.debug('onChatMessages');

    for (const msg of textMessages) {
      this.onChatMessage(msg, true);
    }
  }

  private onPrivateChatMessages(textMessages: HubJsonTextMessage[]) {
    this.logging.debug('onPrivateChatMessages');

    for (const msg of textMessages) {
      this.onPrivateChatMessage(msg);
    }
  }

  private onSystemMessage(controlMessage: EngagementControlMessage) {
    this.logging.debug('onSystemMessage');

    const msg: SystemMessageEvent = {
      type: EngagementServiceEvents.SystemMessage,
      message: controlMessage
    };

    this.events.next(msg);
  }

  private onAgentEndsCall() {
    this.logging.debug('onAgentEndsCall');

    const msg: AgentEndedCall = {
      type: EngagementServiceEvents.AgentEndedCall,
    };

    this.events.next(msg);
  }

  private onVisitorLeftEngagement(visitor: EngagementHubVisitor) {
    this.logging.debug('onVisitorLeftEngagement');

    const msg: VisitorLeftEvent = {
      type: EngagementServiceEvents.VisitorLeft,
      visitor
    };

    this.events.next(msg);
  }

  private onVisitorUpdateTyping(visitorTyping: HubTextMessage) {
    this.logging.debug('onVisitorUpdateTyping');

    const msg: VisitorTypingEvent = {
      type: EngagementServiceEvents.VisitorTyping,
      message: visitorTyping
    };

    this.events.next(msg);
  }

  private updateVisitor(visitor: EngagementHubVisitor) {
    //this.logging.debug('updateVisitor');

    const msg: VisitorUpdateEvent = {
      type: EngagementServiceEvents.VisitorUpdate,
      visitor
    };

    this.events.next(msg);
  }

  private onVisitorNavigation(navigationEvent: NavigationEvent) {
    const msg: VisitorNavigationEvent = {
      type: EngagementServiceEvents.VisitorNavigation,
      navigating: navigationEvent.eventType === 'START',
    };
    this.events.next(msg);
  }

  private onVisitorVerified(response: VerifyResponse) {
    const msg: UserVerifiedEvent = {
      type: EngagementServiceEvents.UserVerified,
      verified: response.Verified,
      username: response.From
    };
    this.events.next(msg);
  }

  private onVisitorConsentOtp(response: VerifyConsentResponse) {
    const msg: UserConsentOtpEvent = {
      type: EngagementServiceEvents.UserConsentOtp,
      consent: response.Consent,
      username: response.From,
      to: response.To
    };
    this.events.next(msg);
  }

  private onStreamState(streamState: StreamState) {
    this.logging.debug('onStreamState');

    const msg: StreamUpdateEvent = {
      type: EngagementServiceEvents.StreamUpdate,
      stream: streamState
    };

    this.events.next(msg);
  }

  private onWebrtcSignalling(message: any) {
    //this.logging.debug('signalling message');

    const msg: WebrtcSignallingEvent = {
      type: EngagementServiceEvents.WebrtcSignallingUpdate,
      message: JSON.parse(message)
    };

    this.events.next(msg);
  }

  private onTransferRejected(transferRejection: TransferRejection) {
    this.logging.debug('onTransferRejected');
    try {
      const msg: TransferRejectedEvent = {
        type: EngagementServiceEvents.TransferRejected,
        operatorUsername: transferRejection.TransferringAgentUserName,
        reason: atob(transferRejection.Reason),
      };
      this.events.next(msg);
    } catch {
      this.logging.debug('Error : onTransferRejected');
    }

    this.logAgentActionAndSwallow(AgentAction.TRANSFER_IN_REJECTED);
  }

  private onTransferAccepted(transferAccepted: TransferAccept) {
    this.logging.debug('onTransferAccepted');
    try {
      const msg: TransferAcceptedEvent = {
        type: EngagementServiceEvents.TransferAccepted,
        operatorUsername: transferAccepted.TransferringAgentUserName
      };
      this.events.next(msg);
    } catch {
      this.logging.debug('Error : onTransferAccepted');
    }

    this.logAgentActionAndSwallow(AgentAction.TRANSFER_IN_ACCEPTED);
  }

  private onScreenshareMessage(screenshareMessage: ScreenShareMessage) {
    this.logging.debug('onScreenshareMessage');
    throw new Error('This is not a supported method currently and should not be called');
  }

  private onCRMStateUpdated(crmData: VisitorCRMData) {
    this.logging.debug('onCRMStateUpdated');

    const msg: CRMUpdateEvent = {
      type: EngagementServiceEvents.CRMUpdated,
      crmData: crmData,
    };

    this.events.next(msg);
  }


  private onLockRoomAgents() {
    this.logging.debug('onLockRoomAgents');

    const msg: AgentLockEvent = {
      type: EngagementServiceEvents.AgentLock,
      isLocked: true,
    };

    this.events.next(msg);
  }

  private onUnlockRoomAgents() {
    this.logging.debug('onUnlockRoomAgents');

    const msg: AgentLockEvent = {
      type: EngagementServiceEvents.AgentLock,
      isLocked: false,
    };

    this.events.next(msg);
  }

  private onKicked(kicking: KickingAgent) {
    this.logging.debug('onKicked');
    const msg: KickedEvent = {
      type: EngagementServiceEvents.Kicked
    };
    this.events.next(msg);
  }

  private onInviteRejected(inviteRejection: InviteRejection) {
    this.logging.debug('onInviteRejected');
    // TODO: check input validity
    const msg: InviteRejectedEvent = {
      type: EngagementServiceEvents.InviteRejected,
      operatorUsername: inviteRejection.InvitedAgentUserName,
      reason: atob(inviteRejection.Reason),
    };
    this.events.next(msg);
  }

  private onInviteAccepted(inviteAccepted: InviteAccept) {
    this.logging.debug('onInviteAccepted');
    try {
      const msg: InviteAcceptedEvent = {
        type: EngagementServiceEvents.InviteAccepted,
        operatorUsername: inviteAccepted.InvitedAgentUserName
      };
      this.events.next(msg);
    } catch {
      this.logging.debug('Error : onInviteAccepted');
    }
  }

  private onDomSyncMessage(domSyncMessage: string) {
    //this.logging.debug('engagementhub.service.ts: onDomSyncMessage: ' + domSyncMessage);
    const msg: DomSyncMessageEvent = {
      type: EngagementServiceEvents.DomSyncMessage,
      message: domSyncMessage
    };
    this.events.next(msg);
  }

  private onDomSyncCommand(domSyncCommand: string) {
    const msg: DomSyncCommandEvent = {
      type: EngagementServiceEvents.DomSyncCommand,
      message: domSyncCommand
    };
    this.events.next(msg);
  }

  private onSwitchCustomer(customerIds: SwitchCustomerIds) {
    this.logging.debug('onSwitchCustomer');
    const msg: SwitchCustomerEvent = {
      type: EngagementServiceEvents.SwitchedCustomer,
      switchedIDs: customerIds
    };
    this.events.next(msg);
  }

  private onGetSuggestion(suggestion: Suggestion) {
    this.logging.debug('onGetSuggestion');
    const msg: ChatSuggestionEvent = {
      type: EngagementServiceEvents.ChatSuggestion,
      message: suggestion.Message
    };
    this.events.next(msg);
  }

  private onAgentAssistAnswered() {
    this.logging.debug('onAgentAssistAnswered');
    const msg: AgentAssistEvent = {
      type: EngagementServiceEvents.AgentAssistAnswered
    };
    this.events.next(msg);
  }

  private onRequestAppViewingStarts() {
    const msg: AppViewStartEvent = {
      type: EngagementServiceEvents.AppViewStartRequest
    };
    this.events.next(msg);
  }

  public sendWebrtcMessage(message: string) {
    this.invoke(EngagementHubMethod.SendWebrtcMessage, message)
      .catch((_) => {
        // Ignoring the message result since these come from a separate component and so the connection
        // to engagement hub can die while attemptin got send a message
      });
  }

  public sendChatMessage(message: string, messageCulture: string, original: string = ''): Promise<HubTextMessage> {
    return this.invoke<HubJsonTextMessage>(EngagementHubMethod.SendChatMessage, message, messageCulture, original)
      .then(textMessage => Promise.resolve(this.toHubTextMessage(textMessage)));
  }

  public sendFileUploadMessage(message: string): Promise<HubTextMessage> {
    return this.invoke<HubJsonTextMessage>(EngagementHubMethod.SendFileUploadMessage, message)
      .then(textMessage => Promise.resolve(this.toHubTextMessage(textMessage)));
  }

  public sendPrivateChatMessage(message: string): Promise<void> {
    return this.invoke(EngagementHubMethod.SendPrivateChatMessage, message);
  }

  public receiveTranslation(message: HubTextMessage): Promise<void> {
    return this.invoke(EngagementHubMethod.ReceiveTranslation, message);
  }

  public chooseFeed(peerId:string, feedType: FeedType, feedId: number, gatewayServer: string) {
    return this.invoke(EngagementHubMethod.ChooseFeed, peerId, feedType, feedId, gatewayServer);
  }

  public endChat(page: string): Promise<void> {
    this.unregisterListeners(); // Unregister listeners so we only get one ended message from server
    this.logAgentActionAndSwallow(AgentAction.END_CALL);
    return this.invoke(EngagementHubMethod.AgentEndsCall, page);
  }

  public showPage(page: string): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.ShowPage, page);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public startSharing(params: string): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.SHARING_START);
    const controlMessage = this.createControlMessage(ControlMessageType.StartSharing, params);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public stopSharing(): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.SHARING_STOP);
    const controlMessage = this.createControlMessage(ControlMessageType.StopScreenShare);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public startDomSync(): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.DOMSYNC_START);
    const controlMessage = this.createControlMessage(ControlMessageType.DomSyncStart, 'vee24');
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public stopDomSync(): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.DOMSYNC_STOP);
    const controlMessage = this.createControlMessage(ControlMessageType.DomSyncCancel);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  // true for pause, false for resume
  public pauseCall(pause: boolean): Promise<void> {
    this.logAgentActionAndSwallow(pause ?  AgentAction.PAUSE_CALL :  AgentAction.RESUME_CALL);
    const controlMessage = this.createControlMessage(pause ? ControlMessageType.PauseCall : ControlMessageType.ResumeCall);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public controlInternalApp(start: boolean): Promise<void> {
    const controlMessage = this.createControlMessage(start ? ControlMessageType.OpStartInternalApp : ControlMessageType.OpStopInternalApp);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public setMicGain(newVolume: number): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.SetMicGain, newVolume.toString());
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public setSpeakerVolume(newVolume: number): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.SetSpeakerVolume, newVolume.toString());
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public hearOp(): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.HEAR_OP);
    const hearOpMessage = this.createControlMessage(ControlMessageType.HearOp);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, hearOpMessage);
  }

  public muteOp(): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.MUTE_OP);
    const muteOpMessage = this.createControlMessage(ControlMessageType.MuteOp);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, muteOpMessage);
  }

  public switchToTextMode(): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.TEXT_MODE);
    const controlMessage = this.createControlMessage(ControlMessageType.TextChatMode);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public switchToAudioMode(): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.AudioOnlyMode);
    this.logAgentActionAndSwallow(AgentAction.AUDIO_MODE);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public switchToVideoMode(): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.VideoMode);
    this.logAgentActionAndSwallow(AgentAction.VIDEO_MODE);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public setPanelSizeSmall(): Promise<void> {
    const p1 = this.invoke(EngagementHubMethod.ChangePanelSize, 0);

    // for older JS / SDK implementations
    const controlMessage = this.createControlMessage(ControlMessageType.MakePanelSmall);
    const p2 = this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
    const p3 = this.logAgentAction(AgentAction.SMALL_VIDEO);
    return Promise.all([p1, p2, p3]).then(() => {});
  }

  public setPanelSizeNormal(): Promise<void> {
    const p1 = this.invoke(EngagementHubMethod.ChangePanelSize, 1);

    // for older JS / SDK implementations

    const controlMessage = this.createControlMessage(ControlMessageType.MakePanelNormal);
    const p2 = this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
    const p3 = this.logAgentAction(AgentAction.NORMAL_VIDEO);
    return Promise.all([p1, p2, p3]).then(() => {});
  }

  public setPanelSizeBig(): Promise<void> {
    const p1 = this.invoke(EngagementHubMethod.ChangePanelSize, 2);

    // for older JS / SDK implementations
    const controlMessage = this.createControlMessage(ControlMessageType.MakePanelBig);
    const p2 = this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
    const p3 = this.logAgentAction(AgentAction.BIG_VIDEO);
    return Promise.all([p1, p2, p3]).then(() => {});
  }

  public setPanelSizeHD(): Promise<void> {
    return this.invoke(EngagementHubMethod.ChangePanelSize, 3);
  }

  public setVideoSizeSmall(): Promise<void> {
    return this.invoke(EngagementHubMethod.ChangeVideoSize, 0);
  }

  public setVideoSizeNormal(): Promise<void> {
    return this.invoke(EngagementHubMethod.ChangeVideoSize, 1);
  }

  public setVideoSizeBig(): Promise<void> {
    return this.invoke(EngagementHubMethod.ChangeVideoSize, 2);
  }

  public setVideoSizeHD(): Promise<void> {
    return this.invoke(EngagementHubMethod.ChangeVideoSize, 3);
  }

  public remotePanelShowDevices(start : boolean, username?: string): Promise<void> {
    return this.invoke(EngagementHubMethod.RemotePanelShowDevices, start.toString(), username);
  }

  public setPanelPosition(hAlign: string, vAlign: string): Promise<void> {
    const message = `${vAlign}:${hAlign}`;
    const p1 = this.invoke(EngagementHubMethod.SetPanelPosition, message);

    // for older JS / SDK implementations
    const controlMessage = this.createControlMessage(ControlMessageType.PositionComsPanel, message);
    const p2 = this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
    const p3 = this.logAgentAction(AgentAction.MOVED_PANEL);
    return Promise.all([p1, p2, p3]).then(() => {});
  }

  public moveComsPanel() {
    // Unused
    return Promise.reject('Not implemented');
  }

  public positionVideo(hAlign: string, vAlign: string): Promise<void> {
    const message = `${vAlign}:${hAlign}`;
    const p1 = this.invoke(EngagementHubMethod.SetPanelPosition, message);

    // for older JS / SDK implementations
    const controlMessage = this.createControlMessage(ControlMessageType.PositionVideo, message);
    const p2 = this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
    return Promise.all([p1, p2]).then(() => {});
  }

  public showText(show: boolean): Promise<void> {
    const p1 = this.invoke(EngagementHubMethod.ShowTextPanel, show);

    // for older JS / SDK implementations
    const controlMessage = this.createControlMessage(show ? ControlMessageType.ShowText : ControlMessageType.HideText);
    const p2 = this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
    const p3 = this.logAgentAction(show ? AgentAction.TEXT_SHOW : AgentAction.TEXT_HIDE);
    return Promise.all([p1, p2, p3]).then(() => {});
  }

  public opTyping(): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.OpTyping, this.operatorNickname);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public opDeletedTyping(): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.OpDeletedTyping, this.operatorNickname);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public showVideo(show: boolean): Promise<void> {
    const controlMessage = this.createControlMessage(show ? ControlMessageType.ShowVideo : ControlMessageType.HideVideo);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public changeCameraDirection(start: boolean, direction: CameraDirection = null): Promise<void> {
    this.logAgentActionAndSwallow(start ? AgentAction.CUSTOMER_CAMERA_ENABLE : AgentAction.CUSTOMER_CAMERA_DISABLE);
    const dir = direction === CameraDirection.FRONT ? 'f' : 'b';
    const controlMessage = this.createControlMessage(start ? ControlMessageType.StartCamera : ControlMessageType.StopCamera, dir);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public startCamera(start: boolean, username: string): Promise<void> {
    this.logAgentActionAndSwallow(start ? AgentAction.CUSTOMER_CAMERA_ENABLE : AgentAction.CUSTOMER_CAMERA_DISABLE);
    return this.invoke(EngagementHubMethod.StartCamera, start.toString(), username);
  }

  public startMic(start: boolean, username: string): Promise<void> {
    this.logAgentActionAndSwallow(start ? AgentAction.CUSTOMERMIC_ON : AgentAction.CUSTOMERMIC_OFF);
    return this.invoke(EngagementHubMethod.StartMic, start.toString(), username);
  }

  public enablePrivateChat(): Promise<void> {
    return this.setRoomProperty('PrivateChatEnabled', 'True');
  }

  public enableAgentAssistBotAutosendMessage(isBotAutoSend: boolean): Promise<void> {
    return this.setRoomProperty('AgentAssistBotAutosendMessage', isBotAutoSend.toString());
  }

  public enableAgentAssistBotGetSuggestions(isBotGetSuggestions: boolean): Promise<void> {
    return this.setRoomProperty('AgentAssistBotGetSuggestions', isBotGetSuggestions.toString());
  }

  public enableAgentAssistBotForRoom(isAgentAssistBotEnable: boolean): Promise<void> {
    return this.setRoomProperty('AgentAssistBotEnabledForRoom', isAgentAssistBotEnable.toString());
  }

  public removeBrowser(): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.RemoveBrowser);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public notifyCustomerOfTransferOut(message: string): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.TransferringUser, message);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public notifyCustomerTransferWasCancelled(message: string): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.CancelTransferringUser, message);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public transferringInAccepted(message: string): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.TransferChat, message);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public sendTransferOutRequest(sitename: string, transferringOperator: string, transferReason: string): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.TRANSFER_OUT_STARTED);
    return this.invoke(EngagementHubMethod.TransferRequest, sitename, transferringOperator, transferReason);
  }

  public abortTransferOutRequest(sitename: string, transferringOperator: string): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.TRANSFER_OUT_CANCELLED);
    return this.invoke(EngagementHubMethod.TransferCancel, sitename, transferringOperator);
  }

  public sendJoinRequest(sitename: string, joiningOperator: string, invitationReason: string): Promise<void> {
    return this.invoke(EngagementHubMethod.JoinRequest, sitename, joiningOperator, invitationReason);
  }

  public abortJoinRequest(sitename: string, joiningOperator: string): Promise<void> {
    return this.invoke(EngagementHubMethod.JoinCancel, sitename, joiningOperator);
  }

  public kickAgent(agentUsername: string): Promise<void> {
    return this.invoke(EngagementHubMethod.Kick, agentUsername);
  }

  public cancelHelpRequest(): Promise<void> {
    return this.invoke(EngagementHubMethod.AgentCancelHelp);
  }

  public requestHelp(): Promise<void> {
    return this.invoke(EngagementHubMethod.AgentRequestsHelp);
  }

  public setRoomProperty(propertyName: string, value: string): Promise<void> {
    return this.invoke(EngagementHubMethod.SetRoomProperty, propertyName, value)
      .then(() => {
        return this.invoke(EngagementHubMethod.UpdateAgentAndGetRoomState, this.getMyState());
      }).then((room: Room) => {
        this.onRoomState(room);
        return Promise.resolve();
    });
  }

  public lockRoom(): Promise<void> {
    return this.invoke(EngagementHubMethod.LockRoomAgents);
  }

  public unlockRoom(): Promise<void> {
    return this.invoke(EngagementHubMethod.UnlockRoomAgents);
  }

  public saveCrmData(userGuid: string, data: VisitorCRMData): Promise<void> {
    return this.invoke(EngagementHubMethod.SaveCRMData, userGuid, data);
  }

  public updateCustomerName(userGuid: string, name: string): Promise<void> {
    return this.invoke(EngagementHubMethod.UpdateCustomerName, userGuid, name);
  }

  public sendDomSyncCommand(message: string): Promise<void> {
    return this.invoke(EngagementHubMethod.DomSyncCommand, message);
  }

  public switchCustomer(currentUserId: string, originalUserId: string): Promise<void> {
    return this.invoke(EngagementHubMethod.SwitchCustomer, currentUserId, originalUserId)
  }

  public showPanel(show: boolean): Promise<void> {
    this.logAgentActionAndSwallow(show ? AgentAction.PANEL_SHOW : AgentAction.PANEL_HIDE);
    return this.invoke(EngagementHubMethod.ShowPanel, show);
  }

  public startAppView(): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.SDK_APPVIEW_SHOW);
    const controlMessage = this.createControlMessage(ControlMessageType.StartAppView);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public stopAppView(): Promise<void> {
    this.logAgentActionAndSwallow(AgentAction.SDK_APPVIEW_HIDE);
    const controlMessage = this.createControlMessage(ControlMessageType.StopAppView);
    return this.invoke(EngagementHubMethod.SendSystemMessageToVisitors, controlMessage);
  }

  public changeEngagementMode(newMode: EngagementMode): Promise<void> {
    return this.invoke(EngagementHubMethod.ChangeEngagementMode, newMode);
  }

  public agentVideoUnDocked(unDocked: boolean): void {
    this.videoUnDocked = unDocked;
  }

  // Exposed for testing
  public logAgentAction(action: AgentAction): Promise<void> {
    const controlMessage = this.createControlMessage(ControlMessageType.LogAgentAction, action.toString());
    return this.invoke(EngagementHubMethod.LogAgentAction, controlMessage);
  }


  public confirmOtp(userGuid: string, otp: string): Promise<void> {
    return this.invoke(EngagementHubMethod.ConfirmOtp, userGuid, otp);
  }

  public sendOtp(userGuid: string, to: string): Promise<string> {
    return this.invoke(EngagementHubMethod.SendOtp , userGuid, to);
  }

  private createControlMessage(type: ControlMessageType, message: string = ''): EngagementControlMessage {
    return {
      MessageType: type,
      Flag: '',
      Message: message,
      Timestamp: new Date(),
      Sender: this.username,
    };
  }

  private toHubTextMessage(textMessage: HubJsonTextMessage): HubTextMessage {
    return {
      ...textMessage,
      TimeStamp: new Date(textMessage.TimeStamp)
    };
  }

  private logAgentActionAndSwallow(action: AgentAction) {
    this.logAgentAction(action).catch(err => {
      this.logging.error(`Unable to log action action ${action}`, err);
    });
  }
}

const enum AgentAction {
  PAUSE_CALL = 1,
  RESUME_CALL = 2,
  TEXT_MODE = 3,
  VIDEO_MODE = 4,
  BROWSER_MODE = 5,
  MOVED_PANEL = 6,
  MOVED_VIDEO = 7,
  PANEL_ENLARGED = 8,
  PANEL_SHRUNK = 9,
  TEXT_SHOW = 10,
  TEXT_HIDE = 11,
  VIDEO_SHOW = 12,
  VIDEO_HIDE = 13,
  CUSTOMER_CAMERA_ENABLE = 14,
  CUSTOMER_CAMERA_DISABLE = 15,
  PANEL_SHOW = 16,
  PANEL_HIDE = 17,
  HEAR_OP = 18,
  MUTE_OP = 19,
  ALLOW_SHARE_OTHER_WINDOWS_ON = 20,
  ALLOW_SHARE_OTHER_WINDOWS_OFF = 21,
  SHARING_MODE_MAIN = 22,
  SHARING_MODE_OS = 23,
  SHARING_MODE_EXTRA = 24,
  SWITCH_CAMERA = 25,
  SDK_VIEWAPPMODE_NONE = 26,
  SDK_VIEWAPPMODE_CLICKING = 27,
  SDK_VIEWAPPMODE_DRAWING = 28,
  SDK_APPVIEW_SHOW = 29,
  SDK_APPVIEW_HIDE = 30,
  SDK_BROWSER_SHOW = 31,
  SDK_BROWSER_HIDE = 32,
  SDK_CAMERA_DIRECTION = 33,
  CUSTOMERMIC_ON = 34,
  CUSTOMERMIC_OFF = 35,
  OPERATORVIDEO_START = 36,
  OPERATORVIDEO_STOP = 37,
  BIG_VIDEO = 38,
  NORMAL_VIDEO = 39,
  SMALL_VIDEO = 40,
  TOGGLE_UPGRADE_ON = 41,
  TOGGLE_UPGRADE_OFF = 42,
  SHARING_START = 43,
  SHARING_STOP = 44,
  END_CALL = 45,
  RECORDING_START = 46,
  RECORDING_STOP = 47,
  COBROWSE_START = 48,
  COBROWSE_STOP = 49,
  DOMSYNC_START = 50,
  DOMSYNC_STOP = 51,
  SDK_VIEWAPPMODE_POINT = 52,
  TRANSFER_OUT_STARTED = 53,
  TRANSFER_OUT_CANCELLED = 54,
  TRANSFER_IN_REJECTED = 55,
  TRANSFER_IN_ACCEPTED = 56,

  AUDIO_MODE= 57,
  JOIN_MODE_INITIAL = 58,
  JOIN_MODE_TRANSFER= 59,
  JOIN_MODE_JOIN= 60,
  JOIN_MODE_SUPERVISOR= 61,
  JOIN_MODE_PRESENTER= 62,
  JOIN_MODE_REJOIN= 63,
}
