import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Engagement} from "../../../services/engagement";
import {EngagementVisitor, UserHeartbeatStates} from "../../../services/engagement-visitor";
import {MenuItem, PrimeIcons} from "primeng/api";
import {TranslatePipe} from "../../../filters/Translate.pipe";
import {StringUtils} from "../../../utils/string-utils";
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-engagement-join-visitor-row',
  templateUrl: './engagement-join-visitor-row.component.html',
  styleUrl: './engagement-join-visitor-row.component.scss'
})
export class EngagementJoinVisitorRowComponent implements OnInit {
  @Input() engagement: Engagement;
  @Input() visitor: EngagementVisitor;
  @Output() kick = new EventEmitter<string>();

  get username() {
    return this.visitor?.userGuid;
  }

  get name() {
    const cName = this.visitor?.customerName$?.value;
    return cName?.trim()?.length > 0 ? cName : this.visitor?.isPrimary ? this.translate.transform('CUSTOMER_LABEL', 'Customer') : this.translate.transform('PARTICIPANT_LABEL', 'Participant');
  }

  get onlineState() {
    return this.visitor?.heartbeatState;
  }

  get state() {
    return this.visitor?.state;
  }

  public menuItems: MenuItem[] = [];

  protected readonly StringUtils = StringUtils;
  protected readonly faEllipsisV = faEllipsisV;

  public removeLbl: string;
  public customerCameraLbl: string;
  public customerMicrophoneLbl: string;
  public customerDevices: string;

  constructor(private translate: TranslatePipe) {
  }


  ngOnInit() {
    this.removeLbl = this.translate.transform('ENGAGEMENT_JOIN_ROW_MAKE_REMOVE', 'Remove from call');
    this.customerCameraLbl = this.translate.transform('ENGAGEMENT_JOIN_ROW_CAMERA', 'Camera');
    this.customerMicrophoneLbl = this.translate.transform('ENGAGEMENT_JOIN_ROW_MICROPHONE', 'Microphone');
    this.customerDevices = this.translate.transform('ENGAGEMENT_JOIN_ROW_DEVICES', 'Devices');


    this.visitor.statusUpdated$.subscribe((data) => {
      this.updateMenuItems();
    });
  }

  hasVisibleMenuItems(): boolean {
    return this.menuItems.some(item => item.visible);
  }

  getStyleClassForOnlineState() {
    if (this.visitor?.heartbeatState === UserHeartbeatStates.ONLINE) {
      return 'surface-green';
    } else if (this.visitor?.heartbeatState === UserHeartbeatStates.BACKGROUND) {
      return 'surface-grey';
    }

    return 'surface-red';
  }


  isPrimaryCustomer() {
    return this.visitor?.isPrimary;
  }

  updateMenuItems(): MenuItem[] {

    const state = this.visitor?.state;

    this.menuItems = [
      {
        id: 'customer-camera-btn',
        label: this.customerCameraLbl,
        icon: PrimeIcons.CAMERA,
        command: () => {
          this.engagement.startCamera(!state?.cameraEnabled, this.username);
        },
        visible: this.engagement?.canHaveAV && state?.hasCameraCapabilities,
        state: {
          toggled: state?.cameraEnabled
        },
        disabled: this.engagement?.chatOnly.getValue()
      },
      {
        id: 'customer-microphone-btn',
        label: this.customerMicrophoneLbl,
        icon: PrimeIcons.MICROPHONE,
        command: () => {
          this.engagement.startMic(!state?.micEnabled, this.username);
        },
        state: {
          toggled: state?.micEnabled
        },
        visible: this.engagement?.canHaveAV && state?.hasMicrophoneCapabilities,
        disabled: this.engagement?.chatOnly.getValue()
      },
      {
        id: 'customer-devices-btn',
        label: this.customerDevices,
        icon: PrimeIcons.COG,
        command: () => {
          this.engagement.remotePanelShowDevices(!state?.devicesVisible, this.username);
        },
        state: {
          toggled: state?.devicesVisible
        },
        visible: this.engagement?.canHaveAV && (state?.hasCameraCapabilities || state?.hasMicrophoneCapabilities),
        disabled: this.engagement?.chatOnly.getValue()
      },
    ];

    const showRemoveFromCall = !this.visitor?.isPrimary;
    if (this.hasVisibleMenuItems() && showRemoveFromCall) {
      this.menuItems.push({separator: true});
    }

    this.menuItems.push({
      id: 'remove-btn',
      label: this.removeLbl,
      icon: PrimeIcons.MINUS_CIRCLE,
      command: () => this.kick.emit(this.username),
      visible: showRemoveFromCall
    });

    return this.menuItems;
  }
}
