import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerSizes, PanelSize, CommunicationMode } from '../../../../services/engagement';
import {
  faCamera, faCog,
  faComment, faComments,
  faCommentSlash,
  faFileAlt, faHeadphones,
  faMicrophone,
  faMicrophoneSlash, faVideo, faVideoSlash, faVolumeMute,
  faVolumeUp
} from '@fortawesome/free-solid-svg-icons';
import { OnlineService } from '../../../../services/online.service';
import { CallType } from '../../../../enums/call-type.enum';

@Component({
  selector: 'app-engagement-controls',
  templateUrl: './engagement-controls.component.html',
  styleUrls: ['./engagement-controls.component.scss']
})
export class EngagementControlsComponent {

  @Input() callType: CallType;

  @Input() textVisible: boolean;
  @Input() mode: CommunicationMode;
  @Input() currentPanelPosition: CustomerSizes;
  @Input() customerViewingOperator: boolean;
  @Input() customerHearingOperator: boolean;
  @Input() videoUnavailable: boolean;
  @Input() audioAvailable: boolean;
  @Input() showAppSize: boolean;

  @Output() switchToTextChat = new EventEmitter<void>();
  @Output() switchToAudioChat = new EventEmitter<void>();
  @Output() switchToVideoChat = new EventEmitter<void>();
  @Output() switchTextVisible = new EventEmitter<boolean>();
  @Output() changePanelSize = new EventEmitter<PanelSize>();
  @Output() panelPositionChanged = new EventEmitter<[string, string]>();

  public CommunicationMode = CommunicationMode; // export for the UI

  CallType = CallType;

  constructor() {
  }

  switchToText() {
    this.switchToTextChat.emit();
  }

  switchToAudio() {
    this.switchToAudioChat.emit();
  }

  switchToVideo() {
    this.switchToVideoChat.emit();
  }

  onTextVisibleChange() {
    if (this.textVisible) {
      this.switchTextVisible.emit(false);
    } else {
      this.switchTextVisible.emit(true);
    }
  }

  private lastSpeakerVolume = 50;
  @Input() speakerVolume = 50;
  @Output() changeSpeakerVolume = new EventEmitter<number>();

  toggleSpeakerMute() { // make a mute event
    if (this.speakerVolume > 0) {
      // We are muting the customer here
      this.lastSpeakerVolume = this.speakerVolume;
      this.changeSpeakerVolume.emit(0);
    } else {
      this.changeSpeakerVolume.emit(this.lastSpeakerVolume / 100);
    }
  }

  @Input() customerVideoOn: boolean;
  @Output() changeCustomerVideo = new EventEmitter<boolean>();

  toggleCustomerVideo() {
    if (this.customerVideoOn) {
      this.changeCustomerVideo.emit(false);
    } else {
      this.changeCustomerVideo.emit(true);
    }
  }

  @Input() customerCogOn: boolean;
  @Output() changeCustomerCog = new EventEmitter<boolean>();
  toggleCustomerCog()
  {
    if(this.customerCogOn) {
      this.changeCustomerCog.emit(false);
    } else {
      this.changeCustomerCog.emit(true);
    }
  }

  @Output() changeCustomerMic = new EventEmitter<boolean>();
  @Input() customerMicOn: boolean;
  toggleCustomerMic() {
    if (this.customerMicOn) {
      this.changeCustomerMic.emit(false);
    } else {
      this.changeCustomerMic.emit(true);
    }
  }

  @Input() isMobile: boolean;
  @Input() isMobileSdk: boolean;
  @Input() isInBackground: boolean;

  protected readonly faCommentSlash = faCommentSlash;
  protected readonly faComment = faComment;
  protected readonly faMicrophoneSlash = faMicrophoneSlash;
  protected readonly faMicrophone = faMicrophone;
  protected readonly faFileAlt = faFileAlt;
  protected readonly faVolumeUp = faVolumeUp;
  protected readonly faVideo = faVideo;
  protected readonly faComments = faComments;
  protected readonly faCamera = faCamera;
  protected readonly faHeadphones = faHeadphones;
  protected readonly faVolumeMute = faVolumeMute;
  protected readonly faVideoSlash = faVideoSlash;
  protected readonly faCog = faCog;
}
