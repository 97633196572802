import {Component, OnInit, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import {EngagementState, Engagement, EngagementStateType, CallInviteState} from '../../../../services/engagement';
import {
  faPencilAlt,
  faInfoCircle,
  faBell,
  faPhoneVolume,
  faComments,
  faUserPlus, faArrowRightArrowLeft, faSquarePollVertical, faHand, faSms, faQuestion
} from '@fortawesome/free-solid-svg-icons';
import {timer} from 'rxjs';
import {CallStatuses} from "../../../../utils/call-statuses";
import {VisitorStatus} from "../../../../enums/EngagementStatus.enum";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {faFacebookMessenger, faWhatsapp} from "@fortawesome/free-brands-svg-icons";

@Component({
  selector: '[app-veechat-call-list-engagement-row]',
  templateUrl: './veechat-call-list-engagement-row.component.html',
  styleUrls: ['./veechat-call-list-engagement-row.component.scss']
})
export class VeechatCallListEngagementRowComponent implements OnInit, OnDestroy {
  private static STATUS_ICON_LOOKUP: Map<EngagementState, string> = new Map([
    [ EngagementState.Engaged, 'assets/call-status-icons/Engaged.svg' ],
    [ EngagementState.Post, 'assets/call-status-icons/Post.svg' ],
    [ EngagementState.Join, 'assets/call-status-icons/Multiparty.svg' ],
    [ EngagementState.Transfer, 'assets/call-status-icons/Transfer.svg' ],
    [ EngagementState.Ended, 'assets/call-status-icons/Post.svg' ]
  ]);

  private static JOIN_STATUS_ICON_LOOKUP: Map<CallInviteState, string> = new Map([
    [ CallInviteState.InviteSelecting, 'assets/call-status-icons/Multiparty.svg' ],
    [ CallInviteState.InviteWaiting, 'assets/call-status-icons/Multiparty.svg' ],
    [ CallInviteState.InviteRejected, 'assets/call-status-icons/Multiparty-reject.svg' ],
    [ CallInviteState.InviteAccepted, 'assets/call-status-icons/Multiparty.svg' ],
  ]);

  private static TRANSFER_STATUS_ICON_LOOKUP: Map<CallInviteState, string> = new Map([
    [ CallInviteState.InviteSelecting, 'assets/call-status-icons/Transfer.svg' ],
    [ CallInviteState.InviteWaiting, 'assets/call-status-icons/Transfer.svg' ],
    [ CallInviteState.InviteRejected, 'assets/call-status-icons/Transfer-reject.svg' ],
    [ CallInviteState.InviteAccepted, 'assets/call-status-icons/Transfer.svg' ],
  ]);
  private static STATUS_ICON_UNKNOWN = 'assets/call-list-icons/empty.svg';

  private static STATUS_TEXT_LOOKUP: Map<EngagementState, string> = new Map([
    [ EngagementState.Engaged, 'Engaged' ],
    [ EngagementState.Post, 'Post engagement' ],
    [ EngagementState.Join, 'Multiparty' ],
    [ EngagementState.Transfer, 'Transferring' ],
    [ EngagementState.Ended, 'Ended' ],
  ]);
  private static STATUS_TEXT_UNKNOWN = '?';
  public static readonly UNKNOWN_STATUS_FONT_ICON =  faQuestion;


  public static readonly STATUS_FONT_ICON_LOOKUP: Map<EngagementState, {icon: IconDefinition, css: string}> = new Map([
    [EngagementState.Engaged, { icon: faComments, css: 'circle-sm surface-orange'}],
    [EngagementState.Post, { icon: faSquarePollVertical, css: 'circle-sm surface-orange'}],
    [EngagementState.Join, { icon: faUserPlus, css: 'circle-sm surface-blue'}],
    [EngagementState.Transfer, { icon: faArrowRightArrowLeft, css: 'circle-sm surface-blue'}],
    [EngagementState.Ended, { icon: faSquarePollVertical, css: 'circle-sm surface-orange'}]
  ]);

  public EngagementState = EngagementState; // export the types for the template
  faPencilAlt = faPencilAlt;
  public readonly engagement: Engagement;

  @Input() chatName = '';
  @Output() renameChat = new EventEmitter<String>();
  @Input() currentState: EngagementStateType;
  @Input() unreadMessages: number;

  private _lastContactTime = new Date();
  @Input() set lastContactTime(lastContactTime: Date) {
    this._lastContactTime = lastContactTime;
    this.calcTimeSinceLastMessage();
  }

  timeSinceLastMessage = '0';

  private lastMessageInterval;

  faInfoCircle = faInfoCircle;
  @Input() currentPage: string;

  constructor() {
  }

  ngOnInit(): void {
    this.lastMessageInterval = timer(1000, 1000).subscribe(() => this.calcTimeSinceLastMessage());
  }

  ngOnDestroy(): void {
    this.lastMessageInterval.unsubscribe();
  }

  public getStatusIcon(state: EngagementStateType): string {
    let icon = '';
    if (state.type === EngagementState.Transfer) {
      icon = VeechatCallListEngagementRowComponent.TRANSFER_STATUS_ICON_LOOKUP.get(state.inviteState);
    } else if (state.type === EngagementState.Join) {
      icon = VeechatCallListEngagementRowComponent.JOIN_STATUS_ICON_LOOKUP.get(state.inviteState);
    } else {
      icon = VeechatCallListEngagementRowComponent.STATUS_ICON_LOOKUP.get(state.type);
    }
    return icon ?? VeechatCallListEngagementRowComponent.STATUS_ICON_UNKNOWN;
  }

  public getStatusText(state: EngagementStateType): string {
    const text = VeechatCallListEngagementRowComponent.STATUS_TEXT_LOOKUP.get(state.type);
    return text ? text : VeechatCallListEngagementRowComponent.STATUS_TEXT_UNKNOWN;
  }

  public  getStatusFontIcon(state: EngagementStateType) {
    return VeechatCallListEngagementRowComponent.STATUS_FONT_ICON_LOOKUP.get(state.type) || {icon: VeechatCallListEngagementRowComponent.UNKNOWN_STATUS_FONT_ICON, css: 'circle-sm surface-grey'};
  }

  private calcTimeSinceLastMessage() {
    this.timeSinceLastMessage = Math.max(0, (new Date().getTime() - this._lastContactTime.getTime()) / 1000).toFixed(0);
  }

  protected readonly CallStatuses = CallStatuses;
}
