<td>
  <!--workaround for p-badge directive bug: https://github.com/primefaces/primeng/issues/12736-->
  <div id="status-icon" [title]="getStatusText(currentState)" [pTooltip]="getStatusText(currentState)" [class]="'p-overlay-badge ' +  getStatusFontIcon(currentState).css">
    <fa-icon [fixedWidth]="true" [icon]="getStatusFontIcon(currentState).icon"></fa-icon>
    <p-badge *ngIf="currentState.type === EngagementState.Engaged && unreadMessages > 0" [value]="unreadMessages" severity="danger" />
  </div>

</td>
<td>
  <p-badge [value]="currentState.type === EngagementState.Engaged ? timeSinceLastMessage : '0'" severity="info" />
</td>
<td colspan="3">
  <app-call-list-rename-chat
    [chatName]="chatName"
    (renameChat)="this.renameChat.emit($event)">
  </app-call-list-rename-chat>
</td>
<td class="has-data-icon" pTooltip="{{currentPage}}">
    <fa-icon size="lg" [icon]="faInfoCircle"  ></fa-icon>
</td>
<!--TODO: should be single component between veechat and async (veechat-call-list-conversation-row-->
