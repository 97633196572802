import { Injectable } from '@angular/core';
import {Browsers} from '../utils/browsers';

@Injectable({
  providedIn: 'root'
})
export class UserAgentDetectorService {

  constructor() { }

  // return true if this user is using safari
  public isSafari(): boolean {
    try {
      const info = Browsers.getBrowserInfo(navigator.userAgent);
      return info.browser.toLowerCase().indexOf('safari') > -1;
    } catch (_) {
      return false;
    }
  }

  // return true if the user is browsing in iOS or iPadOS and safari
  // courtesy of https://stackoverflow.com/a/29696509 and https://developer.apple.com/forums/thread/119186
  public isMobileSafari(): boolean {
    const nav = window.navigator;
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const iPadOS = !!ua.match(/Mac/) && nav.maxTouchPoints && nav.maxTouchPoints == 5;
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = (iOS || iPadOS) && webkit && !ua.match(/CriOS/i);
    return iOSSafari;
  }
}
