<div
  id="videoContainer"
  appHeadPositionOverlay
  [showOverlay]="showHeadPositionOverlay"
  class="d-flex w-100 h-100"
>
  <video
    *ngIf="source"
    class="app-video-preview w-100 h-100  "
    [srcObject]="source"
    autoplay
    playsinline
  ></video>
</div>
